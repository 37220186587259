//import { Base64 } from 'js-base64';
const crypto = require("crypto");

const crypt_key = 'l36DoqKUYQP0N7e1';
//const crypt_iv = '131b0c8a7a6e072e';

//加密
const jiami = function (raw) {
  var iv = crypto.randomBytes(16);
  var key = Buffer.from(crypt_key)

  var cipher = crypto.createCipheriv('aes-128-cbc', key, iv);

  var crypted = cipher.update(JSON.stringify(raw), 'utf8', 'binary');
  crypted += cipher.final('binary');
  crypted = Buffer.from(crypted, 'binary')
  
  // 拼接iv串
  var enc = Buffer.concat([iv, new Buffer(crypted, 'base64')]);
  enc = enc.toString('base64')
  return enc
}

//解密
const jiema = function (raw) {
  raw = Buffer.from(raw, 'base64')
  var iv = raw.slice(0, 16)
  raw = raw.slice(16, raw.length)
  var decipher = crypto.createDecipheriv('aes-128-cbc', crypt_key, iv);
  var decoded = decipher.update(raw, 'binary', 'utf8');
  decoded += decipher.final('utf8');
  return JSON.parse(decoded);
}

// function jiema(token){
//     return JSON.parse(token);
//   }

  export {jiema,jiami}