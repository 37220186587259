export default [
  {
    "value": "绥化市",
    "label": "绥化市",
    "cityType": 3,
    "isCapital": false,
    "children": [
      {
        "value": "市辖区",
        "label": "市辖区",
        "isCity": false
      },
      {
        "value": "北林区",
        "label": "北林区",
        "isCity": false
      },
      {
        "value": "望奎县",
        "label": "望奎县",
        "isCity": false
      },
      {
        "value": "兰西县",
        "label": "兰西县",
        "isCity": false
      },
      {
        "value": "青冈县",
        "label": "青冈县",
        "isCity": false
      },
      {
        "value": "庆安县",
        "label": "庆安县",
        "isCity": false
      },
      {
        "value": "明水县",
        "label": "明水县",
        "isCity": false
      },
      {
        "value": "绥棱县",
        "label": "绥棱县",
        "isCity": false
      },
      {
        "value": "安达市",
        "label": "安达市",
        "isCity": true
      },
      {
        "value": "肇东市",
        "label": "肇东市",
        "isCity": true
      },
      {
        "value": "海伦市",
        "label": "海伦市",
        "isCity": true
      }
    ]
  }
]