<template>
  <div class="info-bar">
    <div id="static-bar-chart" :style="{ width: '100%', height: '100%' }"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "info-bar",
  props: {
    chartData: {
      type: Object,
    },
  },
  data () {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      handler () {
        this.$nextTick(() => {
          this.init();
        });
      },
      deep: true,
    },
  },
  methods: {
    init () {
      let that = this;
      var chartDom = document.getElementById('static-bar-chart');
      this.chart = echarts.init(chartDom);

      const options = {
        title: {
          text: '各区市场监管舆情数量分布',   //主标题
          x: 'center',
          y: 'top',
          textAlign: 'left',
          textStyle: {         //样式
            fontSize: 16
          }
        },
        grid: {
          top: '10%',
          right: '5%',
          left: '5%',
          bottom: '10%'
        },
        tooltip: {
          show: true,
          trigger: "axis",
          axisPointer: {
            type: "shadow",   //提示框类型
            label: {       //坐标轴指示器的文本标签
              show: true
            }
          }
        },
        xAxis: {
          type: "category",
          data: this.chartData.xData,
        },
        yAxis: {
          type: "value",
        },
        series: [{
          data: this.chartData.yData,
          type: 'bar',
          showBackground: false,
          itemStyle: {
            barBorderRadius: [2, 2, 0, 0], //柱体圆角   
            color: new echarts.graphic.LinearGradient(
              //前四个参数用于配置渐变色的起止位置，这四个参数依次对应 右下左上 四个方位。也就是从右边开始顺时针方向。
              //通过修改前4个参数，可以实现不同的渐变方向
              /*第五个参数则是一个数组，用于配置颜色的渐变过程。
                每项为一个对象，包含offset和color两个参数
              */
              0, 0, 0, 1, [{//代表渐变色从正上方开始
                offset: 0, //offset范围是0~1，用于表示位置，0是指0%处的颜色
                color: '#005BEA'
              }, //柱图渐变色
              {
                offset: 1, //指100%处的颜色
                color: '#00C6FB'
              }
            ]
            ),
          },
          barMaxWidth: 30
        }]

      }
      this.chart.setOption(options);

      window.addEventListener("resize", () => {
        that.chart.resize();
      });
    },
  },
};
</script>

<style scoped>
.info-bar {
  width: 100%;
  height: 100%;
}
</style>
