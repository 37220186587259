<template>
  <div style="display: flex">
    <div class="wc" style="flex: 0 0 240px">
      <div>
        <div class="in-t" style="background: #ffffff">任务所属舆情情况</div>
        <div style="background: #ffffff; margin-bottom: 10px">
          <overlay-scrollbars :style="{ height: '240px', width: '240px' }">
            <div class="le_out">
              <div class="le_left">下发人员：</div>
              <div class="le_right" :title="info.issue_user_name">
                {{ info.issue_user_name }}
              </div>
            </div>
            <div class="le_out">
              <div class="le_left">舆情状态：</div>
              <div class="le_right" :title="info.current_status">
                {{ info.current_status }}
              </div>
            </div>
            <div class="le_out">
              <div class="le_left">办结时间：</div>
              <div class="le_right" :title="info.plan_time">
                {{ info.plan_time }}
              </div>
            </div>
            <div class="le_out">
              <div class="le_left">舆情等级：</div>
              <div class="le_right" :title="info.task_level">
                {{ info.task_level }}
              </div>
            </div>
            <div class="le_out">
              <div class="le_left">办理部门：</div>
              <div
                :title="com_execute_department"
                style="flex: 1 1 auto; word-wrap: break-word"
              >
                {{ com_execute_department }}
              </div>
            </div>
            <div class="le_out" v-show="false">
              <div class="le_left">配合部门：</div>
              <div
                :title="com_coop_department"
                style="flex: 1 1 auto; word-wrap: break-word"
              >
                {{ com_coop_department }}
              </div>
            </div>
          </overlay-scrollbars>
        </div>
        <div class="in-b" style="background: #ffffff">
          <div class="in-t" style="background: #ffffff">任务处理情况</div>
          <overlay-scrollbars :style="{ height: windowH - 310 + 'px' }">
            <div style="margin-bottom: 20px">
              <div class="le_out" style="padding-left: 20px">
                <span style="font-weight: bold">{{
                  handle.department_name
                }}</span>
                <span
                  style="
                    line-height: 12px;
                    margin: 6px 0 0 10px;
                    padding: 2px 5px 0 5px;
                    height: 18px;
                    color: #ffffff;
                    border-radius: 12px;
                    background: rgba(0, 204, 153);
                  "
                  >{{ handle.department_role }}</span
                >
              </div>
              <div class="le_out">
                <div class="le_left">处理人员：</div>
                <div class="le_right" :title="handle.user_name">
                  {{ handle.user_name }}
                </div>
              </div>
              <div class="le_out">
                <div class="le_left">处理状态：</div>
                <div class="le_right" :title="handle.current_status">
                  {{ handle.current_status }}
                </div>
              </div>
              <div class="le_out">
                <div class="le_left">处理时间：</div>
                <div class="le_right" :title="handle.current_operate_time">
                  {{ handle.current_operate_time }}
                </div>
              </div>
            </div>
          </overlay-scrollbars>
        </div>
      </div>
    </div>
    <div class="wc" style="flex: 1 1 auto">
      <div class="in">
        <div class="in-t" style="display: flex">
          <div style="flex: 0 0 160px">
           <span @click="toSentimentInfo" class="forbutton" style="display: flex;align-items: center">
            <v-icon name="angle-left" /> 
            <span style="margin-left: 6px">返回舆情列表</span>
          </span>
          </div>
          <div style="flex: 1 1 auto; text-align: right">
            <span
              v-if="
                handle.current_status_digital != 7 &&
                info.current_status_digital != 3
              "
            >
              <AuthFilter authId="2_2_1">
                <span
                  v-if="handle.current_status_digital == 1"
                  class="forbutton"
                  @click="signTask"
                >
                  <i class="el-icon-folder-checked"></i>签收任务</span
                >
              </AuthFilter>
              <AuthFilter authId="2_2_2">
                <span
                  v-if="
                    handle.current_status_digital == 2 ||
                    handle.current_status_digital == 3
                  "
                  class="forbutton"
                  @click="handleTask"
                >
                  <i class="el-icon-timer"></i>处置任务</span
                >
              </AuthFilter>
              <AuthFilter authId="2_2_4">
                <span
                  v-if="
                    handle.current_status_digital == 4 ||
                    handle.current_status_digital == 5
                  "
                  class="forbutton"
                  @click="completeTask"
                >
                  <i class="el-icon-timer"></i>办结任务</span
                >
              </AuthFilter>
              <!-- </span> -->
              <AuthFilter authId="2_2_3">
                <span
                  class="forbutton"
                  v-if="handle.current_status_digital != 1"
                  @click="doContact"
                >
                  <i class="el-icon-chat-dot-round"></i>协同沟通</span
                >
              </AuthFilter>
              <AuthFilter authId="2_2_5">
                <span
                  v-if="handle.current_status_digital != 7"
                  class="forbutton"
                  @click="cancelTask"
                >
                  <i class="el-icon-chat-dot-round"></i>移除任务</span
                >
              </AuthFilter>
            </span>
          </div>
        </div>
        <div class="in-b">
          <div style="border-bottom: solid #efeff4 1px; padding-bottom: 8px">
            <div
              style="
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                line-height: 60px;
                height: 60px;
              "
            >
              {{ info.title }}
            </div>
            <div style="display: flex; font-size: 12px">
              <div style="flex: 0 0 200px; padding-left: 20px">
                信息来源：{{ info.info_resource }}
              </div>
              <div style="flex: 1 1 auto; text-align: right; padding-right: 20px">
                <span class="subInfo">发布媒体：{{ info.author }}</span>
                <!-- <span class="subInfo">文章类型：{{info.info_type}}</span> -->
                <span class="subInfo">报送时间：{{ info.report_time }}</span>
              </div>
            </div>
          </div>
          <!-- <overlay-scrollbars
                        :style="{height: windowH-104 +'px',padding: '20px 40px 20px 40px'}"
                        ref="test"
                        :options="options"
                     > -->
          <div
            class="outContent"
            :style="{
              height: windowH - 104 + 'px',
              overflow: 'auto',
              lineHeigh: '30px',
            }"
          >
            <div class="inContent">
              <div style="width: auto; margin: 0 auto">
                <div
                  style="
                    height: auto;
                    padding: 40px;
                    font-size: 14px;
                    word-break: break-word;
                  "
                  v-html="info.content"
                ></div>
              </div>
              <div style="padding: 20px 0 0 40px; font-size: 12px">
                <span class="subInfo"
                  >原文链接：<a target="_blank" :href="info.resource_link">{{
                    info.resource_link
                  }}</a></span
                >
              </div>
              <div style="padding: 20px 0 0 40px;font-size: 12px">
              <span class="subInfo">转办情况：{{ info.turn_situation || '无' }}</span>
              </div>
            <div style="display: flex;padding: 20px 0 0 40px;font-size: 12px">
              <div style="flex: 0 0 300px;">
                舆情类别：{{ getOpinionTypeName() || '无' }}
              </div>
              <div style="flex: 1 1 auto; padding-right: 20px">
                其他类别：{{ info.other_label || '无' }}
              </div>
            </div>
              <div
                v-if="info.file_url"
                style="padding: 20px 0 0 40px; font-size: 12px"
              >
                <span class="subInfo"
                  >附件：<a
                    style="cursor: pointer"
                    download
                    :href="info.file_url"
                    target="_blank"
                    >下载</a
                  >
                </span>
              </div>
              <div style="padding: 20px">
                <div
                  v-for="(item, index) in contact"
                  :key="index"
                  style="
                    display: flex;
                    border-top: #cccccc solid 1px;
                    border-bottom: #cccccc solid 1px;
                  "
                >
                  <div
                    style="
                      flex: 0 0 160px;
                      min-height: 100px;
                      font-size: 12px;
                      padding: 20px;
                      background: #f9f9f9;
                    "
                  >
                    <div style="line-height: 30px">
                      发送人：{{ item.send_user_name }}
                    </div>
                    <div style="line-height: 30px">
                      所属部门：{{ item.send_department_name }}
                    </div>
                    <div style="line-height: 30px">
                      消息类型：{{ item.type }}
                    </div>
                  </div>
                  <div
                    style="
                      flex: 1 1 auto;
                      border-left: #cccccc solid 1px;
                      min-height: 100px;
                    "
                  >
                    <div
                      style="
                        height: 30px;
                        line-height: 30px;
                        text-align: right;
                        padding-right: 30px;
                        font-size: 12px;
                      "
                    >
                      <span
                        style="display: inline-block; margin-right: 40px"
                        v-if="item.recieve_department_name"
                        >接收部门：{{ item.recieve_department_name }}</span
                      >
                      <span>时间：{{ item.send_time }}</span>
                    </div>
                    <div
                      style="padding: 20px; font-size: 14px"
                      v-html="item.content"
                    ></div>
                    <div
                      v-if="item.file_url"
                      style="
                        height: 30px;
                        line-height: 30px;
                        padding-right: 30px;
                        font-size: 12px;
                        padding-left: 20px;
                      "
                    >
                      <a
                        download
                        :href="item.file_url"
                        target="_blank"
                        style="color: #0055fe; cursor: pointer"
                        >附件下载</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- 拟办意见内容 -->
              <div style="padding: 20px" v-if="info.opinion">
                <h3>拟办意见</h3>
                <div style="padding: 20px">{{ info.opinion }}</div>
              </div>
              <div
                style="padding: 40px 40px 200px 40px; height: 400px"
                v-if="editType === 'handle'"
              >
                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <div style="display: flex; margin-bottom: 20px">
                    <div
                      style="
                        flex: 0 0 100px;
                        font-size: 14px;
                        text-align: right;
                        color: #606266;
                      "
                    >
                      上传附件：
                    </div>
                    <div style="flex: 1 1 auto">
                      <el-upload
                        class="upload-demo"
                        ref="upload"
                        :action="uploadUrl"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        :auto-upload="true"
                        :before-upload="beforeUpload"
                        :headers="header"
                        :multiple="false"
                        :on-success="fileSuccess"
                      >
                        <el-button slot="trigger" size="small" type="primary"
                          >选取文件</el-button
                        >

                        <div slot="tip" class="el-upload__tip">
                          只能上传zip压缩文件，且不超过50M
                        </div>
                      </el-upload>
                    </div>
                  </div>
                  <el-form-item label="处置回复:" prop="handle_content">
                    <editor
                      v-model="ruleForm.handle_content"
                      id="tinymce"
                      :init="init"
                    >
                    </editor>
                  </el-form-item>
                </el-form>
                <div style="padding-left: 100px">
                  <el-button
                    @click="doSaveHandle('ruleForm')"
                    type="primary"
                    plain
                    >提交</el-button
                  >
                </div>
              </div>
              <div
                style="padding: 40px 40px 300px 40px; height: 400px"
                v-if="editType === 'contact'"
              >
                <el-form
                  :model="ruleFormForContact"
                  :rules="rulesForContact"
                  ref="ruleFormForContact"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <el-form-item label="沟通部门:" prop="coop_department">
                    <el-select
                      v-model="ruleFormForContact.coop_department"
                      multiple
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in rel_relative_department"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="沟通内容:" prop="contact_content">
                    <el-input
                      v-model="ruleFormForContact.contact_content"
                      type="textarea"
                      :autosize="{ minRows: 4 }"
                    >
                    </el-input>
                  </el-form-item>
                </el-form>
                <div style="padding-left: 100px">
                  <el-button
                    @click="doSaveContact('ruleFormForContact')"
                    type="primary"
                    plain
                    >提交</el-button
                  >
                </div>
              </div>
              <div style="height: 400px" v-if="editType === ''"></div>
            </div>
          </div>
          <!-- </overlay-scrollbars> -->
        </div>
      </div>
    </div>
    <span @click="goTop" class="upButton" v-if="backTop"
      ><i class="el-icon-caret-top"></i>UP</span
    >
  </div>
</template>
<script>
import { wyAxiosPost } from '../utils/wyAxios'
import axios from 'axios'
import { host } from '../config/host.js'
import tinymce from "tinymce";
import Editor from '@tinymce/tinymce-vue';
import 'tinymce/themes/silver/theme';
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import jq from 'jquery'
import AuthFilter from '../components/authFilter.vue'
import { jiema } from '../utils/jiema.js'
import { dictTranslate } from '../utils/tool.js'

const dataModel = {
  handle_content: ""
};
const dataModelForContact = {
  coop_department: [],
  contact_content: ""
};
export default ({
  name: "TaskDetail",
  components: {
    'editor': Editor,
    AuthFilter
  },
  data () {
    return {
      //附件上传
      header: {},
      file_url: "",
      fileList: [

      ],
      //附件上传路径
      uploadUrl: host + "/addInfo/saveFile",

      options: {},
      backTop: false,
      drawer: false,
      ruleForm: this.$lodash.cloneDeep(dataModel),
      curId: "",
      rules: {
        handle_content: [
          { required: true, message: "不能为空", trigger: 'blur' }
        ],
      },
      ruleFormForContact: this.$lodash.cloneDeep(dataModelForContact),
      rulesForContact: {
        // coop_department:[
        //     { required: true, message:"不能为空",trigger:'blur'}
        // ],
        contact_content: [
          { required: true, message: "不能为空", trigger: 'blur' }
        ],
      },
      initDepartment: [], //除了中心办公室之外的所有部门
      initTaskList: [], //各部门处理的情况列表
      editType: "",
      //富文本配置
      init: {
        selector: "#tinymce", //tinymce的id
        language_url: "/static/tinymce/langs/zh_CN.js",
        language: "zh_CN",
        skin_url: "/static/tinymce/skins/ui/oxide", //编辑器需要一个skin才能正常工作，所以要设置一个skin_url指向之前复制出来的skin文件
        base_url: "/static/tinymce",
        plugins: "image link code table lists wordcount",//引入插件
        font_formats: "微软雅黑=Microsoft YaHei,Helvetica Neue;PingFang SC;sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun;serifsans-serif;Terminal=terminal;monaco;Times New Roman=times new roman;times", //字体
        fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px 50px 56px 60px 64px',//字体列表修改
        toolbar: "fontselect fontsizeselect link lineheight forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | image quicklink h2 h3 blockquote table numlist bullist preview fullscreen", //工具栏
        menubar: false, // 隐藏最上方menu
        // 图片上传三个参数，图片数据，成功时的回调函数，失败时的回调函数
        images_upload_handler: function (blobInfo, success, failure) {
          let formData = new FormData();
          formData.append("image", blobInfo.blob());
          //uploadImg是上传图片的请求接口
          function uploadImg () {
            axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
            let token = localStorage.getItem("scxgo_token");
            if (token) {
              axios.defaults.headers.post['Authorization'] = token;
            }
            const url = host + "/addInfo/saveImg"
            return new Promise(function (resolve, reject) {
              axios({
                method: 'POST',
                url,
                data: formData
              }).then((res) => {
                const { code, data, msg } = res.data
                if (code === 1) {
                  const url = host + "/" + data.url
                  resolve(url);
                } else {
                  reject(msg);
                }

              }).catch(error => {
                console.log("系统级别错误")
                reject(error);
              })
            })
          }
          uploadImg()
            .then(res => {
              //success("https://qnsjk.huabeisky.com/" + res.data);
              success(res);
            })
            .catch(res => {
              console.log(res);
              failure("error");
            });
        }
      },
      info: {},
      handle: {},
      contact: [],
      com_coop_department: "",
      com_execute_department: "",
      login_department_id: "",
      department_id: "",
      relative_department: [],
      rel_relative_department: []
    }
  },
  mounted () {
    tinymce.init({});
    //获取登陆者的department_id
    const token = localStorage.getItem('scxgo_token');
    const rel = jiema(token);
    this.login_department_id = rel.department_id;
    //获取参数query
    const { id } = this.$route.query
    this.curId = id;
    this.haveHeader();

    this.initScroll();
    this.getDetail();

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.toBack, false);
    }
  },
  methods: {
        getOpinionTypeName(){
      if(this.info.opinion_category){
        return dictTranslate(this.info.opinion_category,this.$store.state.opionCategory);
      }

      return '';
    },
    haveHeader () {
      let token = localStorage.getItem("scxgo_token");
      if (token) {
        this.header = { Authorization: token };
      }
    },
    //附件上传之前控制
    beforeUpload (file) {
      var FileExt = file.name.replace(/.+\./, "");
      const isFit = ['zip', 'rar'].indexOf(FileExt.toLowerCase()) === -1;
      if (isFit) {
        this.$message({
          type: 'warning',
          message: '请上传后缀名为zip或rar的附件！'
        });
        return false;
      }
      const isLt2M1 = file.size / 1024 / 1024 < 50;
      if (!isLt2M1) {
        this.$message({
          message: "上传文件大小不能超过50M"
        })
        return false;
      }
      if (this.fileList && this.fileList.length > 0) {
        this.$message({
          message: "只能上传一个附件"
        })
        return false;
      }
      return true;
    },
    fileSuccess (resource, file, fileList) {
      const { code, data } = resource;
      if (code === 1) {
        this.file_url = host + "/" + data.url
        this.fileList = fileList;
      }
    },
    //fujian删除
    handleRemove () {
      this.file_url = "";
      this.fileList = [];
    },
    initScroll () {
      let obj = this.$refs.test;
      let that = this;
      this.options = {
        overflowBehavior: {
          x: "hidden",
          y: "scroll"
        },
        callbacks: {
          onScroll: function () {
            let positionY = obj.osInstance().scroll().position.y;
            //let maxY = obj.obj.osInstance().scroll().max.y;
            if (positionY > 400) {
              that.backTop = true;
            } else {
              that.backTop = false;
            }
          }
        }
      }
    },
    //沟通
    doContact () {

      this.editType = "contact";
      this.ruleFormForContact = this.$lodash.cloneDeep(dataModelForContact);
      // this.$refs.test.osInstance().update();
      // this.$refs.test.osInstance().scroll({y:"100%"},500);
      const outH = jq('.outContent').outerHeight();
      const inH = jq('.inContent').outerHeight();
      jq('.outContent').scrollTop(parseInt(inH - outH));
      if (this.$refs['ruleFormForContact'] != undefined) {
        this.$refs['ruleFormForContact'].resetFields();
      }
    },
    //处置
    handleTask () {
      this.fileList = [];
      this.file_url = "";
      this.ruleForm.handle_content = "";
      this.editType = "handle";
      // this.$refs.test.osInstance().update();
      // this.$refs.test.osInstance().scroll({y:"100%"},500);
      const outH = jq('.outContent').outerHeight();
      const inH = jq('.inContent').outerHeight();
      jq('.outContent').scrollTop(parseInt(inH - outH));
      if (this.$refs['ruleForm'] != undefined) {
        this.$refs['ruleForm'].resetFields();
      }
    },
    //办结任务
    completeTask () {
      this.$confirm('是否确定要办结这个任务吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const id = this.curId;
        wyAxiosPost('/taskDetail/completeTask', { id }, (res) => {
          const { code, msg } = res
          if (code === 1) {
            this.$message.success(msg);
            this.getDetail();
          } else {
            this.$message.warning(msg);
          }

        })
      }).catch(() => {
        return;
      });
    },
    //移除任务
    cancelTask () {
      this.$confirm('是否确定要移除这个任务吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const id = this.curId;
        wyAxiosPost('/taskDetail/cancelDetail', { id }, (res) => {
          const { code, msg } = res
          if (code === 1) {
            this.$message.success(msg);
            this.getDetail();
          } else {
            this.$message.warning(msg);
          }

        })
      }).catch(() => {
        return;
      });
    },
    //跳转到舆情任务页面
    toSentimentInfo () {
      this.toBack();
    },
    // 标记从详情页返回到列表页
    toBack(){
      sessionStorage.setItem("taskDetailBack", true);
      this.$router.history.push("/sentiment/task");
    },
    handleClose () {
      this.ruleForm = this.$lodash.cloneDeep(dataModel);
      this.curId = ""
      this.$refs.ruleForm.resetFields();
      this.drawer = false;
    },
    showDrawer () {
      this.drawer = true;
    },
    doSent () {
      this.showDrawer();
    },

    //获取舆情信息的下发信息
    getSentMsg () {
      const id = this.curId;
      wyAxiosPost('/taskDetail/getSentMsg', { id }, (res) => {
        const { code, data } = res
        if (code === 1) {
          this.initSentMsg = data;
        }
      })
    },
    //获取舆情信息的处理情况
    getTaskInfo () {
      const id = this.curId;
      wyAxiosPost('/taskDetail/getTaskInfo', { id }, (res) => {
        const { code, data } = res
        if (code === 1) {
          this.initTaskList = data;
        }
      })
    },








    //获取该条任务的协同部门列表
    // getCoopDepartment(){
    //     const id = this.curId;
    //     wyAxiosPost('/taskDetail/getCoopDepartment',{id},(res)=>{
    //         const {code, data} = res
    //         if(code === 1){
    //             this.initDepartment = data;
    //         }
    //     })
    // },












    //保存协同信息
    doSaveContact (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = this.ruleFormForContact;
          data.id = this.curId;
          const cur_coop_department = JSON.stringify(this.$lodash.cloneDeep(data.coop_department));
          data.coop_department = cur_coop_department;
          wyAxiosPost('/taskDetail/saveContact', { ...data }, (res) => {
            const { code, msg } = res
            if (code === 1) {
              this.$message({
                type: 'success',
                message: msg
              })
              this.editType = "";
              this.getDetail();
            } else {
              this.$message({
                type: 'warning',
                message: msg
              })
            }
          })
        } else {
          return false;
        }
      });
    },
    doSaveHandle (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = this.ruleForm;
          data.id = this.curId;
          if (this.file_url) {
            data.file_url = this.file_url;
          }
          wyAxiosPost('/taskDetail/saveHandle', { ...data }, (res) => {
            const { code, msg } = res
            if (code === 1) {
              this.$message({
                type: 'success',
                message: msg
              })
              this.editType = "";
              this.getDetail();
            } else {
              this.$message({
                type: 'warning',
                message: msg
              })
            }
          })
        } else {
          return false;
        }
      });
    },
    //获取任务详情的所有信息
    getDetail () {
      const id = this.curId;
      wyAxiosPost('/taskDetail/getDetail', { id }, (res) => {
        const { code, data } = res
        if (code === 1) {
          this.info = data.info;
          this.handle = data.handle;
          if (this.handle && this.handle.department_id) {
            this.department_id = this.handle.department_id;
          }
          this.contact = data.contact
          const coop_department = data.info.coop_department;
          if (coop_department && coop_department.length > 0) {
            this.com_coop_department = coop_department.join(",");
          }
          const execute_department = data.info.execute_department;
          if (execute_department && execute_department.length > 0) {
            this.com_execute_department = execute_department.join(",");
          }

          if (coop_department && coop_department.length > 0) {
            if (execute_department && execute_department.length > 0) {
              this.relative_department = coop_department.concat(execute_department);
            } else {
              this.relative_department = coop_department;
            }
          } else {
            if (execute_department && execute_department.length > 0) {
              this.relative_department = execute_department;
            } else {
              this.relative_department = [];
            }
          }
          this.getExcuteDepartment();
        }
      })
    },
    //获取除了中心部门之外的所有其他部门
    getExcuteDepartment () {
      wyAxiosPost('/detail/getExecuteDepartment', {}, (res) => {
        const { code, data } = res
        if (code === 1) {
          const { relative_department } = this;
          this.initDepartment = data;
          if (data && data.length > 0 && relative_department && relative_department.length > 0) {
            const rel_relative_department = data.filter(o => {
              if (relative_department.indexOf(o.name) != -1) {
                return o;
              }
            })
            this.rel_relative_department = rel_relative_department;
          }

        }
      })
    },
    //签收任务
    signTask () {
      this.$confirm('是否确定要签收这个任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const id = this.curId;
        wyAxiosPost('/taskDetail/sign', { id }, (res) => {
          const { code, msg } = res
          if (code === 1) {
            this.$message.success(msg);
            this.getDetail();
          }
        })
      }).catch(() => {
        return;
      });
    },
    goTop () {
      this.$refs.test.osInstance().update();
      this.$refs.test.osInstance().scroll({ y: "0%" }, 500);
    }
  },
  computed: {
    windowH: function () {
      return this.$store.state.windowH - 140;
    }
  },
  // 组件销毁阶段，解除监听返回按钮的事件
  destroyed() {
    window.removeEventListener("popstate", this.toBack, false);
  },

})
</script>

<style scoped>
.wc {
  padding: 10px 5px 5px 5px;
}
.in {
  width: 100%;
  background: #ffffff;
}
.in-t {
  border-bottom: solid #efeff4 1px;
  height: 40px;
  width: 100%;
  line-height: 40px;
  padding-left: 20px;
  font-size: 14px;
}
.subInfo {
  display: inline-block;
  margin-right: 20px;
}
.le_out {
  display: flex;
  line-height: 30px;
  font-size: 12px;
}
.le_left {
  flex: 0 0 80px;
  padding-left: 20px;
}
.le_right {
  flex: 1 1 auto;
  white-space: nowrap; /*内容超宽后禁止换行显示*/
  overflow: hidden; /*超出部分隐藏*/
  text-overflow: ellipsis; /*文字超出部分以省略号显示*/
}
.upButton {
  position: fixed;
  background: #0055fe;
  bottom: 40px;
  right: 60px;
  cursor: pointer;
  width: 40px;
  padding: 6px 10px;
  border-radius: 3px;
  color: #ffffff;
  opacity: 0.8;
}
.upButton:hover {
  opacity: 0.5;
}
.upButton:active {
  opacity: 1;
}
</style>







