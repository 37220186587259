<template>
  <div>
    <div
      style="
        display: flex;
        line-height: 30px;
        border-bottom: rgba(204, 204, 204, 0.4) solid 1px;
        background: #ffffff;
        margin-bottom: 6px;
        padding: 6px 0 0px 10px;
      "
    >
      <div style="flex: 1 1 auto; font-size: 14px">
        <span style="margin-right: 20px;">
          <el-input
            placeholder="请输入检索内容"
            prefix-icon="el-icon-search"
            v-model="searchStr"
            style="width: 200px"
            @input="doSearch"
            :clearable="true"
            size="mini"
          >
          </el-input>
        </span>
        <span class="forSearch">
          报送时间：
          <el-date-picker
            v-model="searchTime"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期-不限"
            end-placeholder="结束日期-不限"
            value-format="yyyy-MM-dd"
            style="width: 280px"
            size="mini"
          >
          </el-date-picker>
        </span>
        <!-- <span class="forSearch"> 
                    类型：
                    <el-select v-model="searchType" placeholder="请选择" size="mini" style="width: 140px;">
                        <el-option label="新闻" value="新闻"></el-option>
                        <el-option label="论坛" value="论坛"></el-option>
                        <el-option label="微博" value="微博"></el-option>
                        <el-option label="微信" value="微信"></el-option>
                        <el-option label="博客" value="博客"></el-option>
                        <el-option label="其他" value="其他"></el-option>
                    </el-select>
                </span> -->
        <span class="forSearch">
          状态：
          <el-select
            v-model="searchStatus"
            placeholder="请选择"
            size="mini"
            style="width: 140px"
          >
            <el-option label="不限" value=""></el-option>
            <el-option label="未下发" value="1"></el-option>
            <el-option label="已下发" value="2"></el-option>
            <el-option label="已办结" value="3"></el-option>
          </el-select>
        </span>
        <span class="forSearch">
          舆情类别：
          <el-select v-model="opinionCategory" multiple placeholder="请选择">
            <el-option
              v-for="item in opionCategory"
              :key="item.dict_key"
              :label="item.dict_value"
              :value="item.dict_key"
              ></el-option>
          </el-select>
        </span>
      </div>
      <div
        style="
          flex: 0 0 210px;
          text-align: right;
          padding-right: 20px;
          font-size: 14px;
        "
      >
        <AuthFilter authId="1_1">
          <span
            class="forbutton"
            style="color: #67c23a"
            @click="toEditPageNoId"
          >
            <i class="el-icon-edit-outline"></i> 录入舆情</span
          >
        </AuthFilter>
        <AuthFilter authId="1_3">
          <el-popover placement="bottom-end" width="160" v-model="visible">
            <p v-if="ids.length > 0">确定要删除所选项吗？</p>
            <p v-if="ids.length == 0">请先选择要删除的项</p>
            <div style="text-align: right; margin: 0" v-if="ids.length > 0">
              <el-button size="mini" type="text" @click="visible = false"
                >取消</el-button
              >
              <el-button type="primary" size="mini" @click="doDelete"
                >确定</el-button
              >
            </div>
            <div style="text-align: right; margin: 0" v-if="ids.length == 0">
              <el-button type="primary" size="mini" @click="visible = false"
                >好的</el-button
              >
            </div>
            <span
              class="forbutton"
              style="color: #f56c6c"
              slot="reference"
              size="mini"
              type="danger"
            >
              <i class="el-icon-delete"></i> 删除舆情</span
            >
          </el-popover>
        </AuthFilter>
      </div>
    </div>
    <div style="background: #ffffff; padding-bottom: 10px" class="rowTable">
      <overlay-scrollbars :style="{ height: windowH - 40 + 'px' }">
        <el-table
          :data="tableData"
          style="width: calc(100% - 20px)"
          :stripe="true"
          ref="multipleTable"
          :show-overflow-tooltip="true"
          @selection-change="handleSelectionChange"
          @row-click="toDetail"
        >
          <el-table-column width="50" type="selection" :selectable="selectable">
          </el-table-column>
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column prop="title" label="标题"> </el-table-column>
          <el-table-column prop="info_resource" label="信息来源">
          </el-table-column>
          <!-- <el-table-column
                        prop="info_type"
                        label="类型"
                    >
                    </el-table-column> -->
          <!-- <el-table-column
                        prop="publish_time"
                        label="文章发布时间"
                    >
                    </el-table-column> -->
          <el-table-column prop="sent_department" label="报送单位">
          </el-table-column>
          <el-table-column prop="sent_person" label="报送人"> </el-table-column>
          <el-table-column prop="sent_time" label="报送时间"> </el-table-column>
          <el-table-column prop="current_status" label="状态">
            <template slot-scope="scope">
              <el-tag type="warning" v-if="scope.row.current_status == 1"
                ><span>未下发</span></el-tag
              >
              <el-tag type="primary" v-if="scope.row.current_status == 2"
                >已下发</el-tag
              >
              <el-tag
                type="success"
                size="mini"
                v-if="scope.row.current_status == 3"
                >已办结</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column width="100" label="操作">
            <template slot-scope="scope">
              <AuthFilter authId="1_2">
                <el-button
                  v-if="
                    scope.row.current_status === 1 ||
                    scope.row.current_status === 2
                  "
                  icon="el-icon-edit"
                  size="mini"
                  type="primary"
                  @click.stop="toEditPage(scope)"
                  >编辑</el-button
                >
              </AuthFilter>
            </template>
          </el-table-column>
        </el-table>
      </overlay-scrollbars>
      <div style="padding: 10px 0 0 20px">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageInfo.pageSize"
          :hide-on-single-page="false"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total"
          :page-count="pageInfo.pageCount"
          :current-page="pageInfo.currentPage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { wyAxiosPost } from '../utils/wyAxios'
import AuthFilter from '../components/authFilter.vue'
import { jiema } from '../utils/jiema.js'
import { mapState } from "vuex";

export default ({
  name: "SentimentInfo",
  components: {
    AuthFilter
  },
  data () {
    return {
      opinionCategory: [],
      tableData: [],
      ids: [],
      drawer: false,
      pageInfo: {
        pageSize: 10,
        total: 0, //总条目数
        pageCount: 0, //总页数
        currentPage: 1,
      },
      searchStr: "",  //搜索内容
      curId: "",
      visible: false,
      //搜索数据
      searchTime: "", //报送时间
      // searchType:"",//类型
      searchStatus: "", //状态
      user_id: ""
    }
  },
  mounted () {
    if (sessionStorage.getItem("sentimentDetailBack")) {
      //如果有就读取缓存里面的数据
      let sentimentSearchStorage = JSON.parse(sessionStorage.getItem("sentimentSearchStorage"));
      this.$set(this.pageInfo, 'pageSize', sentimentSearchStorage.pageInfo.pageSize);
      this.$set(this.pageInfo, 'currentPage', sentimentSearchStorage.pageInfo.currentPage);
      this.$set(this.pageInfo, 'total', sentimentSearchStorage.pageInfo.total);
      this.searchTime = sentimentSearchStorage.searchTime;
      this.searchStr = sentimentSearchStorage.searchStr;
      this.searchStatus = sentimentSearchStorage.searchStatus;
      this.opinionCategory = sentimentSearchStorage.opinionCategory;

    } else {
      //其他页面第一次进入列表页，清掉缓存里面的数据
      sessionStorage.removeItem("sentimentSearchStorage");
    }
    const { currentPage, pageSize } = this.pageInfo;
    const { searchStr } = this
    //需要得到用户信息控制权限，编辑和删除只能是上传的人才能做
    const token = localStorage.getItem('scxgo_token');
    const rel = jiema(token);
    const user_id = rel.id;
    if (user_id) {
      this.user_id = user_id;
    } else {
      this.$message.warning("您没以后登陆，请登录后再操作");
      localStorage.removeItem("scxgo_token");
      localStorage.removeItem("scxgo_user");
      this.$router.push("/login");
    }
    const { searchTime, searchStatus } = this;

    this.getTableData(currentPage, pageSize, searchStr, searchTime, searchStatus);

    this.$watch('searchTime', this.searchByPageOne);
    this.$watch('searchStatus', this.searchByPageOne);
    this.$watch('opinionCategory', this.searchByPageOne);
  },
  methods: {
    searchByPageOne () {
      this.pageInfo.currentPage = 1;
      const { pageSize } = this.pageInfo;
      const { searchStr, searchTime, searchStatus } = this
      this.getTableData(1, pageSize, searchStr, searchTime, searchStatus);
    },
    //控制复选框是否可选中
    selectable: function (row) {
      // if(row.current_status === 1 && row.report_user_id === this.user_id){
      //     return true;
      // }
      if (row.current_status === 1) {
        return true;
      }
    },
    //分页相关
    handleSizeChange (val) {
      const { searchTime, searchStatus } = this;
      this.getTableData(1, val, this.searchStr, searchTime, searchStatus);
      this.pageInfo.pageSize = val;
      this.pageInfo.currentPage = 1;
    },
    handleCurrentChange (val) {
      const { searchTime, searchStatus } = this;
      this.getTableData(val, this.pageInfo.pageSize, this.searchStr, searchTime, searchStatus);
      this.pageInfo.currentPage = val;
    },
    //搜索
    doSearch () {
      this.pageInfo.currentPage = 1;
      const { pageSize } = this.pageInfo;
      const { searchStr, searchTime, searchStatus } = this
      this.getTableData(1, pageSize, searchStr, searchTime, searchStatus);
    },
    //多选
    handleSelectionChange (val) {
      const ids = [];
      if (val && val.length > 0) {
        val.map(item => {
          ids.push(item.id)
        })
      }
      this.ids = ids;
    },
    showDrawer () {
      this.drawer = true;
    },
    //删除
    doDelete () {
      const ids = JSON.stringify(this.ids);
      wyAxiosPost('/info/delete', { ids }, (res) => {
        const { code, msg } = res
        if (code === 1) {
          this.pageInfo.currentPage = 1;
          const { pageSize } = this.pageInfo;
          const { searchStr, searchTime, searchStatus } = this
          this.getTableData(1, pageSize, searchStr, searchTime, searchStatus);
          this.$message({
            type: 'success',
            message: msg
          })
          this.ids = []
        } else {
          this.$message({
            type: 'warning',
            message: msg
          })
        }
        this.visible = false;
      })
    },

    //获取
    getTableData (pageNum, pageSize, searchStr, searchTime, searchStatus) {
      let opinionCategory = this.opinionCategory.join();
      let strTime = "";
      if (searchTime && searchTime.length > 0) {
        strTime = JSON.stringify(searchTime);
      }
      wyAxiosPost('/info/getList', { pageNum, pageSize, searchStr, searchTime: strTime, searchStatus, opinionCategory }, (res) => {
        const { code, data } = res;
        if (code === 1) {
          if (data.list && data.list.length > 0) {
            this.tableData = data.list;
          } else {
            this.tableData = [];
          }
          this.pageInfo.total = data.total;
        }
      })
    },
    //跳转到舆情录入页面
    toEditPage (scope) {
      const { id } = scope.row
      this.$router.history.push({
        path: "/sentiment/sentimentInfo/addInfo",
        query: {
          id: id
        }
      });
    },
    toEditPageNoId () {
      this.$router.history.push({
        path: "/sentiment/sentimentInfo/addInfo"
      });
    },
    //跳转到详情页面
    toDetail (row) {
      const { id } = row;
      //判断权限
      const token = localStorage.getItem('scxgo_token');
      const rel = jiema(token);
      const { all_auth } = rel;
      if (all_auth.indexOf('1_4_self') != -1) {
        let sentimentSearchStorage = {
          pageInfo: this.pageInfo,
          searchStr: this.searchStr,
          searchTime: this.searchTime,
          searchStatus: this.searchStatus,
          opinionCategory: this.opinionCategory
        }
        sessionStorage.setItem("sentimentSearchStorage", JSON.stringify(sentimentSearchStorage));
        this.$router.history.push({
          path: "/sentiment/sentimentInfo/detail",
          query: {
            id: id
          }
        });
      } else {
        return;
        //this.$message.warning("抱歉，您没有详情的访问权限");
      }
    }
  },
  computed: {
   ...mapState([
      "opionCategory"
    ]),
    windowH: function () {
      return this.$store.state.windowH - 170;
    }
  },
  destroyed () {
    // 销毁组件
    sessionStorage.removeItem("sentimentDetailBack");
  },
})
</script>
<style>
.forbutton {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}
.forbutton:hover {
  color: #0291cc;
  font-weight: bold;
}
.forSearch {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 8px;
}
.rowTable .el-table tbody tr:hover > td {
  cursor: pointer;
  background-color: rgba(2, 145, 204, 0.2) !important;
}
</style>
