<template>
    <div style="height: 30px; paddingTop: 7px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="item in breadList" v-bind:key="item.value" :to="(item.path)" >{{item.name}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
    import { breadNav } from '../config/adminRouteList.js'
    export default ({
        name:"BreadNav",
        data(){
            return {
                breadList:[]
            }
        },
        mounted(){
            const curRouter = this.$router.history.current.path;
            const routeArr = curRouter.split("/");
            let breadArr = [];
            breadNav.map(item=>{
                if(routeArr.indexOf(item.value) != -1){
                    breadArr.push(item);
                }
            })
            this.breadList = breadArr;
        },
        watch:{
            $route(to){
                const curRouter = to.path;
                const routeArr = curRouter.split("/");
                let breadArr = [];
                breadNav.map(item=>{
                    if(routeArr.indexOf(item.value) != -1){
                        breadArr.push(item);
                    }
                })
                this.breadList = breadArr;
            }
        },
        method:{
           
        }

    })
</script>
<style scoped>

</style>