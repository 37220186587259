<template>
  <div :style="{
    width: '100%',
    height: windowH+140+'px',
    background: '#ebecf1', 
    backgroundImage: 'url('+bg01+')',
    backgroundSize: '100% 100%',
    backgroundRepeat:'no-repeat',
    position:'relative'
    }">
    <div :style="{
        
        height: '40px',
        background: '#ffffff',
        lineHeight: '40px',
        paddingLeft: '20px',
        fontWeight: 'bold'

      }">
      <span style="display: inline-block; float: left; margin: 8px 10px 0 0;">
        <!-- <img  :src="logourl" width="24" /> -->
      </span>
      舆情督办系统平台
    </div>
      <div class="formwindow">
        <div :style="{flex: '1 1 auto', height:'450px', backgroundImage: 'url('+bg02+')',backgroundSize: 'cover'}"></div>
        <div style="flex: 0 0  400px;padding: 0 40px; height: 450px;  box-shadow: 0 0 10px #f1f1f1;">
            <div style="lineHeight: 40px;  fontSize: 16px; paddingTop: 60px;">
                <!-- <span style="display: inline-block; float: left; margin: 8px 10px 0 0;"><img  :src="logourl" width="26" /></span> -->
                <span style="display: inline-block; lineHeight: 46px;paddingLeft: 10px;"> 用户登录</span>
            </div>
            <div style="margin: 60px 00 0;">
                <el-form  ref="ruleForm" label-position="left" label-width="10px" :model="userinfo" :rules="rules">
                  <div style="height: 60px;">
                    <el-form-item label="" prop="username">
                      <el-input prefix-icon="el-icon-user" autofocus v-model="userinfo.username" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                  </div>
                  <div style="height: 60px;">
                    <el-form-item label="" prop="password">
                      <el-input prefix-icon="el-icon-key" v-model="userinfo.password" show-password placeholder="请输入登陆密码"></el-input>
                    </el-form-item>
                  </div>
                </el-form>
            </div>
            <!-- <div style="fontSize: 12px;textAlign: right; paddingBottom: 10px; paddingRight: 10px;">还没有账号？去 <span @click="toRegist" style="fontWeight: bold; color: #5675f1; fontSize: 14px; cursor:pointer;"> 注册</span></div> -->
          <div class="btns" style="paddingTop: 60px;">
            <el-button show-password="true" @click="submitForm('ruleForm')" type="primary" style="width: calc(100% - 10px);">登  录</el-button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
  import { wyAxiosPost } from '../utils/wyAxios/index.js'
  import {jiami} from '../utils/jiema.js'

  export default {
    name: 'Login',
    data(){
      return {
        userinfo:{
          username:"",
          password:""
        },
        rules: {
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' }
          ],
          password: [
            {required: true,message: '请输入密码', trigger: 'change'}
          ]
        },
        bg01: require("../assets/loginpg.png"),
        bg02: require("../assets/loginpg2.png"),
        logourl: require('../assets/logo.png'),
      }
    },
    methods: {
      submitForm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const data = {account: this.userinfo.username,password: this.userinfo.password};
            const dataStr = JSON.stringify(data);
            const miwen = jiami(dataStr);
            const param = {param: miwen};
            wyAxiosPost('/user/userLogin',{...param},(res)=>{
              const {data, code } = res;
              if(code && code == 1){
                const {username} = this.$data.userinfo
                const { token } = data;
                localStorage.setItem('scxgo_token',token);
                localStorage.scxgo_user = username;
                this.$store.dispatch('setUserInfo',{username});
                if(username === "sysadmin"){
                  this.$router.push({path:"sentiment/system/department"});
                }
                this.$router.push({path:"sentiment/sentimentInfo"});
              }else{
                this.$message.error(res.msg);
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      toRegist(){
        this.$router.push("/regist");
      }
    },
    mounted(){
      // let screenHeight = document.documentElement.clientHeight;
      // this.windowH = screenHeight;
       const that =  this;
      // window.onresize = () => {
      //   let screenHeight = document.documentElement.clientHeight;
      //   that.windowH = screenHeight;
      //   console.log(that.windowH)
      // };

      document.onkeydown = function() {
        const key = window.event.keyCode;
        if(key === 13){
          that.submitForm('ruleForm');
        }
      }
    },
    computed:{
        windowH:function(){
            return this.$store.state.windowH - 140;
        }
    },
  
  }
</script>

<style scoped>

  .formwindow{
    width: 850px;
    height: auto;
    position: absolute;
    left: 50%;
    margin-left: -425px;
    top: 50%;
    margin-top: -270px;
    display: flex;
    background-color: rgba(255,255,255,1);
  }
  .btns{
    text-align: right;
    
  }
</style>