
//导航列表及权限列表生成
const adminRouteList = [
    {
        name: "舆情信息",
        value:"/sentiment/sentimentInfo",
        authId:"1",
        icon: "list",
        type: "page",
        children:[
            {
                name:"访问",
                authId:"1_self",
                type: "method"
            },{
                name:"舆情录入",
                authId:"1_1",
                value:"/sentiment/sentimentInfo/addInfo",
                type:"method"
            },{
                name:"编辑舆情",
                authId:"1_2",
                type:"method"
            },{
                name:"删除舆情",
                authId:"1_3",
                type:"method"
            },{
                name:"舆情详情",
                authId:"1_4",
                value:"/sentiment/sentimentInfo/detail",
                type:"method",
                children:[
                    {
                        name:"查看",
                        authId:"1_4_self",
                        type: "method"
                    },
                    {
                        name:"下发舆情",
                        authId:"1_4_1",
                        type: "method"
                    },{
                        name:"追加下发",
                        authId:"1_4_2",
                        type: "method"
                    },{
                        name:"舆情办结",
                        authId:"1_4_3",
                        type: "method"
                    }
                    // ,{
                    //     name:"移除下发",
                    //     authId:"1_4_4",
                    //     type: "method"
                    // }
                ]
            }
        ]
    },
    {
        name: "舆情任务",
        value:"/sentiment/task",
        icon: "tasks",
        authId:"2",
        type: "page",
        children:[
            {
                name:"访问",
                authId:"2_self",
                type: "method"
            },{
                name:"导出",
                authId:"2_1",
                type: "method"
            },{
                name:"任务详情",
                authId:"2_2",
                value:"/sentiment/task/taskDetail",
                type:"method",
                children:[
                    {
                        name:"查看",
                        authId:"2_2_self",
                        type: "method"
                    },
                    {
                        name:"签收任务",
                        authId:"2_2_1",
                        type: "method"
                    },{
                        name:"处置任务",
                        authId:"2_2_2",
                        type: "method"
                    },{
                        name:"协同沟通",
                        authId:"2_2_3",
                        type: "method"
                    },{
                        name:"办结任务",
                        authId:"2_2_4",
                        type: "method"
                    },{
                        name:"移除任务",
                        authId:"2_2_5",
                        type: "method"
                    }
                ]
            }
        ]
    },
    {
        name: "统计分析",
        value:"/sentiment/statis",
        icon: "sitemap",
        authId:"3",
        type: "page",
        children:[
            {
                name: "访问",
                value:"/sentiment/statis",
                authId:"3_self",
                type: "method"
            },{
                name:"导出",
                authId:"3_1",
                type: "method"
            }
        ]
    },
    {
        name: "系统设置",
        value:"/sentiment/system",
        icon: "cog",
        authId:"4",
        type: "page",
        children:[
            {
                name:"访问",
                authId:"4_self",
                type: "method"
            },
            {
                name: "部门",
                value:"/sentiment/system/department",
                authId:"4_1",
                type: "page",
                children:[
                    {
                        name: "访问",
                        authId:"4_1_self",
                        type: "method"
                    },
                    {
                        name: "添加",
                        authId:"4_1_1",
                        type: "method"
                    },
                    {
                        name: "删除",
                        authId:"4_1_2",
                        type: "method"
                    },
                    {
                        name: "编辑",
                        authId:"4_1_3",
                        type: "method"
                    }
                ]
            },
            {
                name: "用户",
                value:"/sentiment/system/user",
                authId:"4_2",
                type: "page",
                children:[
                    {
                        name: "访问",
                        authId:"4_2_self",
                        type: "method"
                    }, {
                        name: "添加",
                        value:"addInfo",
                        authId:"4_2_1",
                        type: "method"
                    },
                    {
                        name: "删除",
                        value:"deleteInfo",
                        authId:"4_2_2",
                        type: "method"
                    },
                    {
                        name: "编辑",
                        authId:"4_2_3",
                        type: "method"
                    }
                ]
            },
            {
                name: "角色",
                value:"/sentiment/system/role",
                authId:"4_3",
                type: "page",
                children:[
                    {
                        name: "访问",
                        authId:"4_3_self",
                        type: "method"
                    }, {
                        name: "分配权限",
                        authId:"4_3_1",
                        type: "method"
                    },{
                        name: "添加",
                        authId:"4_3_2",
                        type: "method"
                    },
                    {
                        name: "删除",
                        authId:"4_3_3",
                        type: "method"
                    },
                    {
                        name: "编辑",
                        authId:"4_3_4",
                        type: "method"
                    }
                ]
            }
        ]
    },
    {
        name: "消息推送",
        authId:"5",
        type: "method",
        children:[
            {
                name:"公众号舆情消息通知",
                authId:"5_1",
                type: "method"
            },
            {
                name:"公众号舆情任务通知",
                authId:"5_2",
                type: "method"
            },
            {
                name:"公众号舆情已处置通知",
                authId:"5_3",
                type: "method"
            },
            {
                name:"舆情下发短信通知",
                authId:"5_4",
                type: "method"
            }
        ]
    }
]

//供面包屑导航使用,以及url直接访问控制
const  breadNav = [
    {name:"首页",value:"sentiment",path:"/sentiment/sentimentInfo",authId:"1"},
    /////////////////////////////////////舆情信息
    {name:"舆情信息",value:"sentimentInfo",path:"/sentiment/sentimentInfo",authId:"1"},
    {name:"舆情录入",value:"addInfo",path:"/sentiment/sentimentInfo/addInfo",authId:"1_1"},
    {name:"舆情详情",value:"detail",path:"/sentiment/sentimentInfo/detail",authId:"1_4"},
    /////////////////////////////////////舆情任务
    {name:"舆情任务",value:"task",path:"/sentiment/task",authId:"2"},
    {name:"任务详情",value:"taskDetail",path:"/sentiment/task/taskDetail",authId:"2_2"},
    /////////////////////////////////////统计分析
    {name:"统计分析",value:"statis",path:"/sentiment/statis",authId:"3"},
    //////////////////////////////////////系统设置
    {name:"系统设置",value:"system",path:"/sentiment/system",authId:"4"},
    {name:"部门",value:"department",path:"/sentiment/system/department",authId:"4_1"},
    {name:"用户",value:"user",path:"/sentiment/system/user",authId:"4_2"},
    {name:"角色",value:"role",path:"/sentiment/system/role",authId:"4_3"}
]



export {adminRouteList,breadNav}





//["1","1_self","1_1","1_2","4","4_self","4_1","4_1_self","4_1_1","4_1_2","4_1_3","4_2","4_2_self","4_2_1","4_2_2","4_2_3","4_3","4_3_self","4_3_1","4_3_2","4_3_3","4_3_4"]