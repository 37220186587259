<template>
    <div style="background: #ffffff;padding: 20px;">
        <div style="display: flex;  lineHeight: 40px; borderBottom: rgba(204,204,204,0.4) solid 1px; paddingBottom: 10px;">
            <div style="flex: 1 1 auto;">
                <el-input
                    placeholder="请输入检索内容"
                    prefix-icon="el-icon-search"
                    v-model="searchStr"
                    style="width: 200px;"
                    @input="doSearch"
                    :clearable=true
                    size="mini"
                >
                </el-input>
            </div>
            <div style="flex: 0 0 310px; textAlign: right;paddingRight: 20px;">
                <AuthFilter authId="4_3_2">
                    <el-button type="primary" size="mini" icon="el-icon-plus" @click="doAdd" >添加</el-button>
                </AuthFilter>
                <AuthFilter authId="4_3_3">
                    <el-popover
                        placement="bottom-end"
                        width="160"
                        v-model="visible">
                        <p v-if="ids.length>0">确定要删除所选项吗？</p>
                        <p v-if="ids.length==0">请先选择要删除的项</p>
                        <div style="text-align: right; margin: 0" v-if="ids.length>0">
                            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                            <el-button type="primary" size="mini" @click="doDelete">确定</el-button>
                        </div>
                        <div style="text-align: right; margin: 0" v-if="ids.length==0">
                            <el-button type="primary" size="mini" @click="visible=false">好的</el-button>
                        </div>
                        <el-button icon="el-icon-minus" style="marginLeft: 20px;" slot="reference" size="mini" type="danger">删除</el-button>
                    </el-popover>
                </AuthFilter>
            </div>
        </div> 
        <div> 
            <overlay-scrollbars :style="{height: windowH - 70 +'px'}">
                <el-table
                    :data="tableData"
                    style="width: calc(100% - 20px)"
                    :stripe="true"
                    ref="multipleTable"
                    :show-overflow-tooltip="true"
                    @selection-change="handleSelectionChange">
                >   
                    <el-table-column
                        width="50"
                        type="selection"
                        :selectable="selectable"
                        
                    >
                    </el-table-column>
                    <el-table-column
                        type="index"
                        width="50"
                        
                    >
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="角色名称"
                        
                    >
                    </el-table-column>
                    <!-- <el-table-column
                        prop="department_name"
                        label="所属部门"
                        
                    >
                    </el-table-column> -->
                    <el-table-column
                        prop="create_time"
                        label="创建时间"
                        
                    >
                    </el-table-column>
                    <el-table-column width="260" label="操作">
                        <template slot-scope="scope">
                            <span v-if="scope.row.id != 3">
                                <span>
                                    <AuthFilter authId="4_3_1">
                                        <el-button
                                        icon="el-icon-finished"
                                        size="mini"
                                        type="success"
                                        @click="handleEdit(scope.$index, scope.row)">分配权限</el-button>
                                    </AuthFilter>
                                </span>
                                <span style="display: inline-block;marginLeft: 20px;">
                                    <AuthFilter authId="4_3_4">
                                        <el-button
                                        icon="el-icon-edit"
                                        size="mini"
                                        type="primary"
                                        @click="doEdit(scope.$index, scope.row)">编辑</el-button>
                                    </AuthFilter>
                                </span>
                            </span>
                        
                        </template>
                    </el-table-column>
                </el-table>
            </overlay-scrollbars>
            <div style="padding: 10px 0 0 20px;">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    :hide-on-single-page=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total"
                    :page-count="pageInfo.pageCount"
                    :current-page="pageInfo.currentPage"
                >
                </el-pagination>
            </div>
            <el-drawer
                title="权限"
                :visible.sync="drawer"
                :before-close="handleClose"
            >
                <overlay-scrollbars 
                        ref="userManageComponentRef"
                        :style="{height: windowH+55+'px',width:'100%',paddingRight: '60px'}"
                        overflowBehavior="{x:'hidden',y:'scroll'}"
                >
                    <el-tree
                        :data="treeData"
                        show-checkbox
                        node-key="authId"
                        :expand-on-click-node="true"
                        :check-on-click-node="true"
                        :props="defaultProps"
                        :default-checked-keys="defaultKeys"
                        @check-change="checkChange"
                        ref="atree"
                        :render-after-expand="false"
                        :default-expand-all="true"
                    >
                    </el-tree>
                </overlay-scrollbars>
                <div style="display: flex; height: 40px; lineHeight: 40px;">
                    <div style="flex: 0 0  50%;"><el-button @click="doSave('ruleForm')" style="width: 100%; borderRadius: 0;" type="primary">确定</el-button></div>
                    <div style="flex: 0 0  50%;"><el-button @click="handleClose" style="width: 100%; borderRadius: 0;">取消</el-button></div>
                </div>
            </el-drawer>


            <el-drawer
                :title="taskType === 'add'?'添加角色':'编辑角色'"
                :visible.sync="drawerInfo"
                :before-close="handleCloseInfo"
                :size="400"
            >
                <overlay-scrollbars 
                    :style="{height: windowH+55+'px',width:'100%',paddingRight: '60px'}"
                    overflowBehavior="{x:'hidden',y:'scroll'}"
                >
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="角色名称" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item> 
                        <!-- <el-form-item label="所属部门" prop="department">
                            <el-select v-model="ruleForm.department" placeholder="请先选择部门" style="width: 100%;">
                                <el-option v-for="item in departmentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item> -->
                    </el-form>
                </overlay-scrollbars>
                <div style="display: flex; height: 40px; lineHeight: 40px;">
                    <div style="flex: 0 0  50%;"><el-button @click="saveRoleInfo('ruleForm')" style="width: 100%; borderRadius: 0;" type="primary">确定</el-button></div>
                    <div style="flex: 0 0  50%;"><el-button @click="handleCloseInfo" style="width: 100%; borderRadius: 0;">取消</el-button></div>
                </div>
            </el-drawer>
        </div>
    </div>
</template>

<script>
    import { wyAxiosPost } from '../utils/wyAxios'
    import { adminRouteList } from '../config/adminRouteList.js'
    import AuthFilter from '../components/authFilter.vue'
    const dataModel = {
        name:"",
        //department:""
    }
    export default ({
        name:"Department",
        components:{
            AuthFilter
        },
        data(){
            return {
                tableData: [],
                treeData: adminRouteList,
                defaultKeys:[], //初始选中的叶子节点
                allKeys:[], //所有被选中的节点，包括半选和全选
                leafKeys:[],//被选中的叶子节点
                defaultProps:{
                    children: 'children',
                    label: 'name',
                    id: 'authId'
                },
                ids:[],
                drawer: false,
                pageInfo:{
                    pageSize: 10,
                    total: 0, //总条目数
                    pageCount: 0, //总页数
                    currentPage: 1,
                },
                searchStr:"",  //搜索内容
 
                curId:"",
                ruleForm: this.$lodash.cloneDeep(dataModel),
                rules: {
                    name:[
                        { required: true, message:"不能为空",trigger:'blur'}
                    ],
                    department:[
                        { required: true, message:"不能为空",trigger:'blur'}
                    ]
                },
                taskType:"add",
                drawerInfo: false,
                departmentList:[],
                visible: false
          
            }
        },
        mounted(){
            const { currentPage, pageSize } = this.pageInfo;
            const { searchStr } = this
            const pageNum = currentPage;
            this.getTableData(pageNum,pageSize,searchStr);
            // this.getAllDepartment();
        },
        methods:{
            //控制系统管理员不可删除
            selectable:function(row){
                if(row.id != 3){
                    return true;
                }
            },
            //获取所有部门
            getAllDepartment(){
                wyAxiosPost('/manage/role/getAllDepartment',{},(res)=>{
                    const {code,data} = res;
                    if(code == 1){
                        this.departmentList = data;
                    }
                })
            },
            //添加角色
            doAdd(){
                this.taskType = "add";
                this.ruleForm = this.$lodash.cloneDeep(dataModel);
                this.drawerInfo = true;
            },
            //取消
            handleCloseInfo(){
                this.ruleForm = this.$lodash.cloneDeep(dataModel);
                this.taskType = "add";
                this.curId = ""
                this.$refs.ruleForm.resetFields();
                this.drawerInfo = false;
            },
            //编辑
            doEdit(index,row){
                this.taskType = "edit"
                const {
                    id,
                    name,
                    //department_id
                } = row
                
                this.ruleForm = {
                    id,
                    name,
                    //department: department_id
                }
                this.curId = id;
                this.drawerInfo =  true;
            },
            //保存
            saveRoleInfo(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        const data = this.$lodash.cloneDeep(this.ruleForm); 
                        if(this.curId){
                            data.id = this.curId;
                        }
                        wyAxiosPost('/manage/role/save',{data},(res)=>{
                            const {code, msg} = res
                            if(code == 1){
                                this.$message({
                                    type: 'success',
                                    message: msg
                                })
                                const {currentPage,pageSize} = this.pageInfo
                                this.handleCloseInfo();
                                this.getTableData(currentPage,pageSize,this.searchStr);
                            }else{
                                this.$message({
                                    type: 'warning',
                                    message: msg
                                })
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            //删除角色
            doDelete(){
                const ids = JSON.stringify(this.$lodash.cloneDeep(this.ids));
                wyAxiosPost('/manage/role/delete',{ids},(res)=>{
                    const { code, msg } = res
                    if(code == 1){
                        this.pageInfo.currentPage = 1;
                        const { pageSize } = this.pageInfo;
                        const { searchStr } = this
                        this.getTableData(1,pageSize,searchStr);
                        this.$message({
                            type: 'success',
                            message: msg
                        })
                        this.ids = []
                    }else{
                        this.$message({
                            type: 'warning',
                            message: msg
                        })
                    }
                    this.visible = false;
                })
            },
            //权限
            checkChange(){
                const checkedKeys = this.$refs.atree.getCheckedKeys();
                const halfCheckedKeys = this.$refs.atree.getHalfCheckedKeys();
                const newArr = checkedKeys.concat(halfCheckedKeys);
                this.allKeys = newArr;
                this.leafKeys = checkedKeys;
            },
            //分页相关
            handleSizeChange(val) {
                this.pageInfo.pageSize = val;
                this.pageInfo.currentPage = 1;
                this.getTableData(1,val,this.searchStr);
                
            },
            handleCurrentChange(val) {
                this.pageInfo.currentPage = val;
                this.getTableData(val,this.pageInfo.pageSize,this.searchStr);
                
            },
            //搜索
            doSearch(){
                this.pageInfo.currentPage = 1;
                this.getTableData(1,this.pageInfo.pageSize,this.searchStr);
            },
            //多选
            handleSelectionChange(val) {
                const ids = [];
                if(val && val.length>0){
                    val.map(item=>{
                        ids.push(item.id)
                    })
                }
                this.ids = ids;
            },
            //取消
            handleClose(){
                this.curId = ""
                this.drawer = false;
                this.allKeys = [];
                this.leafKeys = [];
                this.defaultKeys = [];
                this.$refs.atree.setCheckedKeys([]);
            },
            showDrawer(){
                this.drawer = true;
            },
            //保存
            doSave(){
                const { curId, allKeys,leafKeys } = this;
                const id = curId;
                const all_auth = JSON.stringify(allKeys);
                const leaf_auth = JSON.stringify(leafKeys);
                wyAxiosPost('/manage/role/save',{ id, all_auth, leaf_auth },(res)=>{
                    const {code,msg} = res;
                    if(code  == 1){
                        this.$message({
                            message: msg,
                            type: 'success'
                        })
                        this.handleClose();
                        const { currentPage,pageSize } = this.pageInfo
                        const { searchStr } = this;
                        const pageNum = currentPage;
                        this.getTableData(pageNum,pageSize,searchStr);
                    }else if(code == 2){
                        this.$message({
                            message: msg,
                            type: 'success'
                        })
                        this.handleClose();
                        const { currentPage,pageSize } = this.pageInfo
                        const { searchStr } = this;
                        const pageNum = currentPage;
                        this.getTableData(pageNum,pageSize,searchStr);
                        this.open();
                    }else{
                        this.$message({
                            message: msg,
                            type: 'warning'
                        })
                    }
                })
            },
            open(){
                this.$confirm('您刚才修改了自己所属角色权限, 是否重新登录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    localStorage.removeItem("scxgo_token");
                    localStorage.removeItem("scxgo_user");
                    this.$router.push("/login");
                }).catch(() => {
                   return;    
                });
            },
       
            //获取
            getTableData(pageNum,pageSize,searchStr){
                wyAxiosPost('/manage/role/getList',{pageNum,pageSize,searchStr},(res)=>{
                    const { code, data } = res;
                    if(code === 1){
                        const {total,list} = data;
                        this.tableData = list;
                        this.pageInfo.total = total;
                    }
                })
            },
            //分配权限
            handleEdit(index,row){
                this.drawer = true;
                this.curId = row.id;
                this.allKeys = JSON.parse(row.all_auth);
                this.leafKeys = JSON.parse(row.leaf_auth);
                this.defaultKeys = JSON.parse(row.leaf_auth);
                const leaf_auth = JSON.parse(this.$lodash.cloneDeep(row.leaf_auth));
                if(this.$refs.atree){
                    this.$refs.atree.setCheckedKeys(leaf_auth,true);
                }
                
            }
        },

        computed:{
            windowH:function(){
                return this.$store.state.windowH - 170;
            }
        }, 
    })
</script>
<style scoped>

</style>
