import { wyAxiosGet } from '../utils/wyAxios/index.js'



export default {
    state: {
        windowH: 0,
        navName: {
            path:"",
            name:""
        },
        userInfo: {
            username:""
        },
        opionCategory: [],
        taskCategory: []
    },
    mutations: {
        //修改windowH
        windowHReducer(state,params){
            state.windowH = params;
        },
        navReducer(state,params){
            state.navName = params
        },
        userInfoReducer(state,params){
            state.userInfo = params
        },
        setOpionCategory(state,params){
          state.opionCategory = params;
        },
        setTaskCategory(state,params){
          state.taskCategory = params;
        }   
    },
    actions: {
        changeWindowH(context,params){
            let {commit} = context;
            commit('windowHReducer',params);
        },
        changeNav(context,params){
            let {commit}  = context;
            commit('navReducer',params)
        },
        setUserInfo(context,params){
            let {commit} = context;
            commit('userInfoReducer',params);
        },
        getOpionCategory(context){
          let {commit} = context;
          wyAxiosGet('/getCategory',{ type: 1},(res) => {
            const { code, data } = res;
            if (code === 1) {
              commit('setOpionCategory',data);
            }
          })
        },
        getTaskCategory(context){
          let {commit} = context;
          wyAxiosGet('/getCategory',{ type: 2},(res) => {
            const { code, data } = res;
            if (code === 1) {
              commit('setTaskCategory',data);
            }
          })
        },
    }
}

