<template>
    <div style="background: #ffffff;padding: 20px;">
        <div style="display: flex;  lineHeight: 40px; borderBottom: rgba(204,204,204,0.4) solid 1px; paddingBottom: 10px;">
            <div style="flex:0 0  220px;">
                部门：<el-select v-model="department_id" clearable  filterable placeholder="请选择部门" size="mini" style="width: 140px;">
                    <el-option
                    v-for="item in departmentList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div style="flex:0 0  220px;">
                角色：<el-select v-model="role_id" clearable  filterable placeholder="请选择角色" size="mini" style="width: 140px;">
                    <el-option
                    v-for="item in roleList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </div>
            
            <div style="flex: 1 1 auto;">
                用户：<el-input
                    placeholder="请输入检索内容"
                    prefix-icon="el-icon-search"
                    v-model="searchStr"
                    style="width: 200px;"
                    @input="doSearch"
                    :clearable=true
                    size="mini"
                >
                </el-input>
            </div>
            <div style="flex: 0 0 310px; textAlign: right;paddingRight: 20px;">
                <AuthFilter authId="4_2_1">
                    <el-button type="primary" size="mini" icon="el-icon-plus" @click="doAdd" >添加</el-button>
                </AuthFilter>
                <AuthFilter authId="4_2_2">
                    <el-popover
                        placement="bottom-end"
                        width="160"
                        v-model="visible">
                        <p v-if="ids.length>0">确定要删除所选项吗？</p>
                        <p v-if="ids.length==0">请先选择要删除的项</p>
                        <div style="text-align: right; margin: 0" v-if="ids.length>0">
                            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                            <el-button type="primary" size="mini" @click="doDelete">确定</el-button>
                        </div>
                        <div style="text-align: right; margin: 0" v-if="ids.length==0">
                            <el-button type="primary" size="mini" @click="visible=false">好的</el-button>
                        </div>
                        <el-button icon="el-icon-minus" style="marginLeft: 20px;" slot="reference" size="mini" type="danger">删除</el-button>
                    </el-popover>
                </AuthFilter>
                
            </div>
        </div> 
        <div> 
            <overlay-scrollbars :style="{height: windowH - 70 +'px'}">
                <el-table
                    :data="tableData"
                    style="width: calc(100% - 20px)"
                    :stripe="true"
                    ref="multipleTable"
                    :show-overflow-tooltip="true"
                    @selection-change="handleSelectionChange">
                >   
                    <el-table-column
                        width="50"
                        type="selection"
                        :selectable="selectable"
                        
                    >
                    </el-table-column>
                    <el-table-column
                        type="index"
                        width="50"
                        
                    >
                    </el-table-column>
                    <el-table-column
                        prop="account"
                        label="用户名"
                        
                    >
                    </el-table-column>
                    <el-table-column
                        prop="department"
                        label="所属部门"
                        
                        
                    >
                    </el-table-column>
                    <el-table-column
                        prop="role"
                        label="角色"
                        

                    >


                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="姓名"
                        
                    >
                    </el-table-column>
                    
                    <el-table-column
                        prop="mobile"
                        label="手机号"
                    >
                       
                    </el-table-column>
                    <el-table-column width="100" label="操作">
                        <template slot-scope="scope">
                            <span v-if="scope.row.id != 2">
                                <AuthFilter authId="4_2_3">
                                    <el-button
                                    icon="el-icon-edit"
                                    size="mini"
                                    type="primary"
                                    @click="doEdit(scope.$index, scope.row)">编辑</el-button>
                                </AuthFilter>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </overlay-scrollbars>
            <div style="padding: 10px 0 0 20px;">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    :hide-on-single-page=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total"
                    :page-count="pageInfo.pageCount"
                    :current-page="pageInfo.currentPage"
                >
                </el-pagination>
            </div>
            <el-drawer
                :title="taskType === 'add'?'添加用户':'编辑用户'"
                :visible.sync="drawer"
                :before-close="handleClose"
                :size="400"
            >
                <overlay-scrollbars 
                    ref="userManageComponentRef"
                    :style="{height: windowH+55+'px',width:'100%',paddingRight: '60px'}"
                    overflowBehavior="{x:'hidden',y:'scroll'}"
                >
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="用户名" prop="account">
                            <el-input :disabled="taskType == 'edit'" v-model="ruleForm.account"></el-input>
                        </el-form-item> 
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <el-form-item v-if="taskType=='add'" label="密码" prop="password">
                            <el-input type="password" v-model="ruleForm.password"></el-input>
                        </el-form-item>
                        <el-form-item v-if="taskType=='add'" label="重复密码" prop="password_repeat">
                            <el-input type="password" v-model="ruleForm.password_repeat"></el-input>
                        </el-form-item>
                        <el-form-item label="所属部门" prop="department_id">
                            <el-select v-model="ruleForm.department_id" placeholder="请选择" style="width: 100%;">
                                <el-option label="无" value=""></el-option>
                                <el-option v-for="item in departmentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="角色" prop="role_id">
                            <el-select v-model="ruleForm.role_id" placeholder="请先选择部门" style="width: 100%;">
                                <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="手机号码" prop="mobile">
                            <el-input  v-model="ruleForm.mobile"></el-input>
                        </el-form-item>
                    </el-form>
                </overlay-scrollbars>
                <div style="display: flex; height: 40px; lineHeight: 40px;">
                    <div style="flex: 0 0  50%;"><el-button @click="doSave('ruleForm')" style="width: 100%; borderRadius: 0;" type="primary">确定</el-button></div>
                    <div style="flex: 0 0  50%;"><el-button @click="handleClose" style="width: 100%; borderRadius: 0;">取消</el-button></div>
                </div>
            </el-drawer>
        </div>
    </div>
</template>

<script>
    import { wyAxiosPost } from '../utils/wyAxios'
    import AuthFilter from '../components/authFilter.vue'
    import {jiami,jiema} from '../utils/jiema.js'
    const dataModel = {
        name: "",
        account: "",
        password:"",
        password_repeat:"",
        mobile:"",
        role_id:"",
        //department_id:""
    };
    export default ({
        name:"Department",
        components:{
            AuthFilter
        },
        data(){
            const validatePass = (rule, value, callback) => {
                if (value.trim() == '') {
                    callback(new Error('请输入密码'));
                } else if (this.ruleForm.password_repeat != "" && value != this.ruleForm.password_repeat) {
                    callback(new Error('两次输入密码不一致!'));
                }
                callback();
            };
            const validatePass2 = (rule, value, callback) => {
                if (value.trim() === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.ruleForm.password) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            const mobile = (rule,value,cb)=>{
                const reg = /^1[3456789]\d{9}$/;
                if(!reg.test(value) && value){
                    cb(new Error("手机号格式不正确"))
                }
                cb();
            }
            return {
                tableData: [],
                ids:[],
                drawer: false,
                pageInfo:{
                    pageSize: 10,
                    total: 0, //总条目数
                    pageCount: 0, //总页数
                    currentPage: 1,
                },
                searchStr:"",  //搜索内容
                ruleForm: this.$lodash.cloneDeep(dataModel),
                curId:"",
                rules: {
                    name: [
                        { required: true, message: '请输入用户名', trigger: 'blur' }
                    ],
                    account: [
                        { required: true, message: '请选择角色', trigger: 'blur' }
                    ],
                    mobile:[
                        { required: true, message:"不能为空",trigger:'blur'},
                        { validator: mobile, trigger: 'blur' }
                    ],
                    password:[
                        { required: true, message:'不能为空',trigger:"blur"},
                        { validator: validatePass, trigger: 'blur' }
                    ],
                    password_repeat:[
                        { required: true, message:'不能为空',trigger:"blur"},
                        { validator: validatePass2, trigger: 'blur' }
                    ],
                    department_id:[
                        { required: true, message:"不能为空",trigger:'blur'}
                    ],
                    role_id:[
                        { required: true, message:"不能为空",trigger:'blur'}
                    ],
                },
                taskType: "",
                visible: false,
                roleList: [],
                departmentList:[],
                roleForFileter: [],
                departmentForFileter: [],
                department_id:"",
                role_id:""
         
            }
        },
        mounted(){
            const { currentPage, pageSize } = this.pageInfo;
            const { searchStr,department_id,role_id } = this
            this.getTableData(currentPage,pageSize,searchStr,department_id,role_id);
            this.getAllDepartment();
            this.getRoleByDepartmentId();
        },
        methods:{
            filterDepartment(value, row) {
                return row.department == value;
            },
            filterRole(value,row){
                return row.role == value;
            },
            //控制系统管理员不可删除
            selectable:function(row){
                if(row.id != 2){
                    return true;
                }
            },
            formatRole (row, column, cellValue) {
                if (cellValue && cellValue.length>0){
                    const haha = cellValue.join(' , ');
                    return haha;
                }else{
                    return '';
                }
            },
            //分页相关
            handleSizeChange(val) {
                const { searchStr, department_id, role_id} = this;
                this.getTableData(1,val,searchStr,department_id,role_id);
                this.pageInfo.pageSize = val;
                this.pageInfo.currentPage = 1;
            },
            handleCurrentChange(val) {
                const { searchStr, department_id, role_id } = this;
                this.getTableData(val,this.pageInfo.pageSize,searchStr,department_id,role_id);
                this.pageInfo.currentPage = val;
            },
            //搜索
            doSearch(){
                this.pageInfo.currentPage = 1;
                const { pageSize } = this.pageInfo;
                const { searchStr,department_id, role_id } = this
                this.getTableData(1,pageSize,searchStr,department_id,role_id);
            },
            //多选
            handleSelectionChange(val) {
                const ids = [];
                if(val && val.length>0){
                    val.map(item=>{
                        ids.push(item.id)
                    })
                }
                this.ids = ids;
            },
            //取消
            handleClose(){
                this.ruleForm = this.$lodash.cloneDeep(dataModel);
                this.taskType = "add";
                this.curId = ""
                this.$refs.ruleForm.resetFields();
                this.drawer = false;
                
            },
            showDrawer(){
                this.drawer = true;
            },
            //编辑
            doEdit(index,row){
                this.taskType = "edit"
                const {
                    id,
                    department_id,
                } = row
                

                this.getRoleByDepartmentId(department_id);
                this.curId = id;
                wyAxiosPost('/user/getUserById',{id},(res)=>{
                    const { code, data,msg} = res;
                    if(code == 1){
                        const userinfo = jiema(data.user);
                        const { name,
                            account,
                            mobile,
                            department_id,
                            role_id
                        } = userinfo;
                        this.ruleForm = {
                            name,
                            account,
                            mobile,
                            department_id,
                            role_id
                        }
                        this.showDrawer();
                    }else{
                        this.$message.error(msg);
                    }
                })
            },
            //保存
            doSave(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        const data = this.$lodash.cloneDeep(this.ruleForm); 
                        if(this.curId){
                            data.id = this.curId;
                        }
                        const param = jiami(JSON.stringify(data))
                        wyAxiosPost('/manage/user/save',{param},(res)=>{
                            const {code, msg} = res
                            if(code == 1){
                                this.$message({
                                    type: 'success',
                                    message: msg
                                })
                                const {currentPage,pageSize} = this.pageInfo
                                const {searchStr, department_id, role_id } = this;
                                this.handleClose();
                                this.getTableData(currentPage,pageSize,searchStr,department_id, role_id);
                            }else{
                                this.$message({
                                    type: 'warning',
                                    message: msg
                                })
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            //删除
            doDelete(){
                const ids = JSON.stringify(this.$lodash.cloneDeep(this.ids));
                wyAxiosPost('/manage/user/delete',{ids},(res)=>{
                    const { code, msg } = res
                    if(code == 1){
                        this.pageInfo.currentPage = 1;
                        const { pageSize } = this.pageInfo;
                        const { searchStr,department_id, role_id } = this

                        this.getTableData(1,pageSize,searchStr,department_id,role_id);
                        this.$message({
                            type: 'success',
                            message: msg
                        })
                        this.ids = []
                    }else{
                        this.$message({
                            type: 'warning',
                            message: msg
                        })
                    }
                    this.visible = false;
                })
            },
            //添加
            doAdd(){
                this.taskType = "add";
                this.showDrawer();
                this.ruleForm = this.$lodash.cloneDeep(dataModel);
            },
            //获取
            getTableData(pageNum,pageSize,searchStr,department_id,role_id){
                wyAxiosPost('/manage/user/getList',{pageNum,pageSize,searchStr,department_id,role_id},(res)=>{
                    const { code, data } = res;
                    if(code === 1){
                        let list = this.$lodash.cloneDeep(data.list);
                        if(list && list.length>0){
                            list.map(item=>{
                                if(item.name && item.name.length>0){
                                    let curname = item.name;
                                    let newname = "";
                                    for(let i=0; i<curname.length;i++){
                                        if(i == 0){
                                            newname += curname[i];
                                        }else{
                                            newname += "*";
                                        }
                                    }

                                    item.name = newname;
                                }

                                if(item.mobile && item.mobile.length>0){
                                    let curphone = item.mobile;
                                    let newphone = "";
                                    for(let i=0; i<curphone.length;i++){
                                        if([3,4,5,6].indexOf(i) == -1){
                                            newphone += curphone[i];
                                        }else{
                                            newphone += "*";
                                        }
                                    }

                                    item.mobile = newphone;

                                }
                            })


                        }
                        
                        this.tableData = list;
                        this.pageInfo.total = data.total;
                    }
                })
            },
            //获取所有部门
            getAllDepartment(){
                wyAxiosPost('/manage/user/getAllDepartment',{},(res)=>{
                    const { code, data } = res;
                    if(code == 1){
                        this.departmentList = data;
                        const departmentForFileter = [];
                        if(data && data.length>0){
                            data.map(item=>{
                                departmentForFileter.push({
                                    text: item.name,
                                    value: item.name
                                });
                            })
                        }
                        this.departmentForFileter = departmentForFileter;

                    }
                })
            },
            //获取所有角色
            getRoleByDepartmentId(){
                wyAxiosPost('/manage/user/getAllRole',{},(res)=>{
                    const { code, data } = res;
                    if(code == 1){
                        this.roleList = data;
                        const roleForFileter = [];
                        if(data && data.length>0){
                            data.map(item=>{
                                roleForFileter.push({
                                    text: item.name,
                                    value: item.name
                                });
                            })
                        }
                        this.roleForFileter = roleForFileter;
                        
                    }
                })
            }
        },
        watch:{
                department_id(){
                    this.pageInfo.currentPage = 1;
                    const { pageSize } = this.pageInfo;
                    const { searchStr,department_id, role_id } = this
                    this.getTableData(1,pageSize,searchStr,department_id,role_id);
                },
                role_id(){
                    this.pageInfo.currentPage = 1;
                    const { pageSize } = this.pageInfo;
                    const { searchStr,department_id, role_id } = this
                    this.getTableData(1,pageSize,searchStr,department_id,role_id);
                }
            },

        computed:{
            windowH:function(){
                return this.$store.state.windowH - 170;
            },
            curdepartment(){
                return this.ruleForm.department_id;
            }
        }
        
    })
</script>
<style scoped>

</style>
