<template>
    <div>
     首页
    </div>
</template>
<script>

export default ({
    name:"Home",

    data(){
        return {
         
        }
    },
    mounted(){
       
    },
    computed:{
        windowH:function(){
            return this.$store.state.windowH - 140;
        }
    },

})
</script>

<style scoped>
    .listCont{
        width:100%;
        display: flex;
        
    }
    .title{
        flex: 0 0 30px;
        line-height: 30px;
        color: #ffffff;
        writing-mode:vertical-lr;
        text-align: center;
        margin: 5px 5px 5px 0;
    }
    .picCount{
        flex: 1 1 auto;
        border-left-style: solid;
        border-left-width: 4px;
        margin: 5px;
        background: #ffffff;
    }
    .textCount{
        flex: 0 0 160px;
        border-top-style: solid;
        border-top-width: 4px;
        margin: 5px;
        background: #ffffff;
        padding: 10px;
    }
</style>