<template>
  <div :style="{width: '100%',height: windowH+'px',backgroundImage: 'url('+bg01+')'}">
    
    <div class="formForRegist">
      <div style=" padding: 40px 60px; height: auto; background: rgba(255,255,255,0.5)">
      
        <el-form  ref="ruleFormForRegist" label-position="left" label-width="80px" :model="ruleForm" :rules="rules">
            <el-form-item label="手机号:" prop="userphone">
                <el-input autofocus v-model="ruleForm.userphone"></el-input>
            </el-form-item>
            <el-form-item  label="密码" prop="password">
                <el-input show-password v-model="ruleForm.password"></el-input>
            </el-form-item>
            <el-form-item label="重复密码" prop="password_repeat">
                <el-input show-password v-model="ruleForm.password_repeat"></el-input>
            </el-form-item>
            <el-form-item  label="主体名称" prop="theme_name">
                <el-input placeholder="企业营业执照上的名称" v-model="ruleForm.theme_name"></el-input>
            </el-form-item>
            <el-form-item  label="场所地址" prop="place_address">
                <el-input  v-model="ruleForm.place_address"></el-input>
            </el-form-item>
            <el-form-item  label="负责人" prop="leader">
                <el-input v-model="ruleForm.leader"></el-input>
            </el-form-item>
            <el-form-item label="行业类型" prop="industry_type">
                <el-select v-model="ruleForm.industry_type" placeholder="请选择行业类型" style="width: 100%;">
                    <el-option v-for="item in nodeList" :key="item.id" :label="item.industry_name" :value="item.industry_type"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属市县" prop="city_counties">
                <el-cascader
                    v-model="ruleForm.city_counties"
                    :options="citys"
                    :clearable="true"
                    filterable
                    style="width: 100%;"
                >
                </el-cascader>
            </el-form-item>
        </el-form>

        <div class="btnsForRegist">
          <el-button show-password="true" @click="submitForm('ruleFormForRegist')" type="primary" style="width: calc(100% - 80px);">确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
  import { wyAxiosPost } from '../utils/wyAxios/index.js'
   import citys from '../utils/city.js'
  export default {
    name: 'Regist',
    data(){
        const validatePass = (rule, value, callback) => {
            if (value == '') {
                callback(new Error('请输入密码'));
            } else if (this.ruleForm.password_repeat != "" && value != this.ruleForm.password_repeat) {
                callback(new Error('两次输入密码不一致!'));
            }
            callback();
        };
        const validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.password && this.ruleForm.password != "") {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        const mobile = (rule,value,callback)=>{
            const reg = /^1[3456789]\d{9}$/;
            if(value == ""){
                callback(new Error("请输入手机号码"))
            }else if(value != "" && !reg.test(value)){
                callback(new Error("手机号格式不正确"))
            }else{
                callback();
            }
            
        }
      return {
        ruleForm:{
          userphone:"",
          password:"",
          password_repeat:"",
          theme_name:"",
          place_address:"",
          leader:"",
          industry_type:"",
          city_counties:""
        },
        rules: {
            userphone:[
                { required: true, message:'不能为空',trigger:"blur"},
                {validator: mobile, trigger: 'blur'}
            ],
            password:[
                { required: true, message:'不能为空',trigger:"blur"},
                { validator: validatePass, trigger: 'blur' }
            ],
            password_repeat:[
                { required: true, message:'不能为空',trigger:"blur"},
                { validator: validatePass2, trigger: 'blur' }
            ],
            theme_name:[
                { required: true, message:'不能为空',trigger:"blur"}
            ],
            place_address:[
                { required: true, message:'不能为空',trigger:"blur"}
            ],
            leader:[
                { required: true, message:'不能为空',trigger:"blur"}
            ],
            industry_type:[
                { required: true, message:'不能为空',trigger:"blur"}
            ],
            city_counties:[
                { required: true, message:'不能为空',trigger:"blur"}
            ],
        },
        bg01: require("../assets/bg01.png"),
        bg02: require("../assets/bg02.png"),
        windowH: 100,
        citys: citys,
        nodeList:[]
      }
    },
    methods: {
      submitForm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            wyAxiosPost('/User/UserRegister',{...this.$data.ruleForm},(res)=>{
                const {status,msg} = res;
              if(status && status === 1){
                this.$message({
                    type:"success",
                    message: msg
                })
                this.$router.push({path:"/login"});
              }else{
                this.$message.error(msg);
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      getNodeList(){
        wyAxiosPost('/Entity/getAllRole',{},(res)=>{
          const {status, msg} = res;
          if(status === 1){
            this.nodeList = msg;
          }
        })
      }
    },
    mounted(){
      this.getNodeList();
      let screenHeight = document.documentElement.clientHeight;
      this.windowH = screenHeight;
      const that =  this;
      window.onresize = () => {
        let screenHeight = document.documentElement.clientHeight;
        that.windowH = screenHeight;
      };

      document.onkeydown = function() {
        const key = window.event.keyCode;
        if(key === 13){
          that.submitForm('ruleForm');
        }
      }
      
    }
  
  }
</script>

<style scoped>

  .formForRegist{

    width: 700px;
    height: 520px;
    position: fixed;
    left: 50%;
    margin-left: -350px;
    top: 50%;
    margin-top: -280px;

  }
  .btnsForRegist{
    text-align: right;
    
  }
</style>