<template>
    <div style="background: #ffffff;padding: 20px;">
        <div style="display: flex;  lineHeight: 40px; borderBottom: rgba(204,204,204,0.4) solid 1px; paddingBottom: 10px;">
            <div style="flex: 1 1 auto;">
                <el-input
                    placeholder="请输入检索内容"
                    prefix-icon="el-icon-search"
                    v-model="searchStr"
                    style="width: 200px;"
                    @input="doSearch"
                    :clearable=true
                    size="mini"
                >
                </el-input>
            </div>
            <div style="flex: 0 0 310px; textAlign: right;paddingRight: 20px;">
                <AuthFilter authId="4_1_1">
                    <el-button type="primary" size="mini" icon="el-icon-plus" @click="doAdd" >添加</el-button>
                </AuthFilter>
                <AuthFilter authId="4_1_2">
                    <el-popover
                        placement="bottom-end"
                        width="160"
                        v-model="visible">
                        <p v-if="ids.length>0">确定要删除所选项吗？</p>
                        <p v-if="ids.length==0">请先选择要删除的项</p>
                        <div style="text-align: right; margin: 0" v-if="ids.length>0">
                            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                            <el-button type="primary" size="mini" @click="doDelete">确定</el-button>
                        </div>
                        <div style="text-align: right; margin: 0" v-if="ids.length==0">
                            <el-button type="primary" size="mini" @click="visible=false">好的</el-button>
                        </div>
                        <el-button icon="el-icon-minus" style="marginLeft: 20px;" slot="reference" size="mini" type="danger">删除</el-button>
                    </el-popover>
                </AuthFilter>
            </div>
        </div> 
        <div> 
            <overlay-scrollbars :style="{height: windowH - 70 +'px'}">
                <el-table
                    :data="tableData"
                    style="width: calc(100% - 20px)"
                    :stripe="true"
                    ref="multipleTable"
                    :show-overflow-tooltip="true"
                    @selection-change="handleSelectionChange">
                >   
                    <el-table-column
                        width="50"
                        type="selection"
                    >
                    </el-table-column>
                    <el-table-column
                        type="index"
                        width="50"
                        
                    >
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="部门名称"
                        
                    >
                    </el-table-column>
                    <el-table-column
                        prop="guide_department"
                        label="指导部门"
                        
                    >
                    </el-table-column>
                        <el-table-column width="100" label="操作">
                            <template slot-scope="scope">
                                <AuthFilter authId="4_1_3">
                                    <el-button
                                    icon="el-icon-edit"
                                    size="mini"
                                    type="primary"
                                    @click="doEdit(scope.$index, scope.row)">编辑</el-button>
                                </AuthFilter>
                            </template>
                        </el-table-column>
                    
                </el-table>
            </overlay-scrollbars>
            <div style="padding: 10px 0 0 20px;">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    :hide-on-single-page=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total"
                    :page-count="pageInfo.pageCount"
                    :current-page="pageInfo.currentPage"
                >
                </el-pagination>
            </div>
            <el-drawer
                :title="taskType === 'add'?'添加部门':'编辑部门'"
                :visible.sync="drawer"
                :before-close="handleClose"
            >
                <overlay-scrollbars 
                        ref="userManageComponentRef"
                        :style="{height: windowH+55+'px',width:'100%',paddingRight: '60px'}"
                        overflowBehavior="{x:'hidden',y:'scroll'}"
                >
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                        <el-form-item label="部门名称" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item> 
                        <el-form-item label="指导部门" prop="guide_department">
                            <el-select v-model="ruleForm.guide_department">
                                <el-option label="无指导部门" value="0"></el-option>
                                <el-option v-for="item in upDepartments" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </overlay-scrollbars>
                <div style="display: flex; height: 40px; lineHeight: 40px;">
                    <div style="flex: 0 0  50%;"><el-button @click="doSave('ruleForm')" style="width: 100%; borderRadius: 0;" type="primary">确定</el-button></div>
                    <div style="flex: 0 0  50%;"><el-button @click="handleClose" style="width: 100%; borderRadius: 0;">取消</el-button></div>
                </div>
            </el-drawer>
        </div>
    </div>
</template>

<script>
    import { wyAxiosPost } from '../utils/wyAxios'
    import AuthFilter from '../components/authFilter.vue'
    const dataModel = {
        name: "",
        guide_department: ""
    };
    export default ({
        name:"Department",
        components:{
            AuthFilter
        },
        data(){
            return {
                tableData: [],
                ids:[],
                drawer: false,
                pageInfo:{
                    pageSize: 10,
                    total: 0, //总条目数
                    pageCount: 0, //总页数
                    currentPage: 1,
                },
                searchStr:"",  //搜索内容
                ruleForm:this.$lodash.cloneDeep(dataModel),
                curId:"",
                rules: {
                    name:[
                        { required: true, message:"不能为空",trigger:'blur'}
                    ],
                    guide_department:[
                        { required: true, message:"不能为空",trigger:'blur'}
                    ]
                },
                taskType: "",
                visible: false,
                areaList:[],
                area:"",
                themeList:[],
                theme_id:"",
                upDepartments: [] //所有上级部门
            }
        },
        mounted(){
            const { currentPage, pageSize } = this.pageInfo;
            const { searchStr } = this
            this.getTableData(currentPage,pageSize,searchStr);
            this.getAllUpDepartment();
        },
        methods:{
            //获取上级部门
            getAllUpDepartment(){
                wyAxiosPost('/manage/department/getAllUpDepartment',{},(res)=>{
                    const {code, data} = res
                    if(code === 1){
                        this.upDepartments = data;
                    }
                })
            },
            //控制复选框是否可选中
            selectable:function(row){
                if(row.isHave && row.isHave == 0){
                    return true;
                }
            },
            //分页相关
            handleSizeChange(val) {
                this.getTableData(1,val,this.searchStr);
                this.pageInfo.pageSize = val;
                this.pageInfo.currentPage = 1;
            },
            handleCurrentChange(val) {
                this.getTableData(val,this.pageInfo.pageSize,this.searchStr);
                this.pageInfo.currentPage = val;
            },
            //搜索
            doSearch(){
                this.pageInfo.currentPage = 1;
                const { pageSize } = this.pageInfo;
                const { searchStr } = this
                this.getTableData(1,pageSize,searchStr);
            },
            //多选
            handleSelectionChange(val) {
                const ids = [];
                if(val && val.length>0){
                    val.map(item=>{
                        ids.push(item.id)
                    })
                }
                this.ids = ids;
            },
            //取消
            handleClose(){
                this.ruleForm = this.$lodash.cloneDeep(dataModel);
                this.taskType = "";
                this.curId = ""
                this.$refs.ruleForm.resetFields();
                this.drawer = false;
                
            },
            showDrawer(){
                this.drawer = true;
            },
            //编辑
            doEdit(index,row){
                const {
                    id,
                    name,
                    sid
                } = row
                
                this.ruleForm = {
                   name,
                   guide_department:sid
                }
                this.curId = id;
                this.taskType = "edit"
                this.showDrawer();
            },
            //保存
            doSave(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        const data = this.ruleForm 
                        console.log(this.ruleForm)
                        if(this.curId){
                            data.id = this.curId;
                        }
                        wyAxiosPost('/manage/department/save',{...data},(res)=>{
                            const {code, msg} = res
                            if(code === 1){
                                this.$message({
                                    type: 'success',
                                    message: msg
                                })
                                const {currentPage,pageSize} = this.pageInfo
                                this.getTableData(currentPage,pageSize,this.searchStr);
                                this.getAllUpDepartment();
                                this.handleClose();
                            }else{
                                this.$message({
                                    type: 'warning',
                                    message: msg
                                })
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            //删除
            doDelete(){
                const ids = JSON.stringify(this.ids);
                wyAxiosPost('/manage/department/delete',{ids},(res)=>{
                    const { code, msg } = res
                    if(code === 1){
                        this.pageInfo.currentPage = 1;
                        const { pageSize } = this.pageInfo;
                        const { searchStr } = this
                        this.getTableData(1,pageSize,searchStr);
                        this.$message({
                            type: 'success',
                            message: msg
                        })
                        this.ids = []
                    }else{
                        this.$message({
                            type: 'warning',
                            message: msg
                        })
                    }
                    this.visible = false;
                })
            },
            //添加
            doAdd(){
                this.taskType = "add";
                this.showDrawer();
                this.ruleForm = this.$lodash.cloneDeep(dataModel);
            },
            //获取
            getTableData(pageNum,pageSize,searchStr){
                wyAxiosPost('/manage/department/getList',{pageNum,pageSize,searchStr},(res)=>{
                    const { code, data } = res;
                    if(code === 1){
                        this.tableData = data.list;
                        this.pageInfo.total = data.total;
                    }
                })
            }
        },

        computed:{
            windowH:function(){
                return this.$store.state.windowH - 170;
            }
        }, 
    })
</script>
<style scoped>

</style>
