<template>
    <span v-if="isShow">
        <slot></slot>
    </span>
</template>


<script>
 import { jiema } from '../utils/jiema.js'
    export default {
        name: 'AuthFilter',
        props:['authId'],
        data(){
            return {
                isShow: false
            }
        },
        mounted(){
            const {all_auth} = jiema(localStorage.getItem('scxgo_token'));
            const id = this.authId;
            if(all_auth && all_auth.indexOf(id)!=-1){
                this.isShow = true;
            }
        }
    }
</script>
