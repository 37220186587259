<template>
    <div>
        <div class="header" :style="{
            display: 'flex',
            
            background: 'linear-gradient(0deg,rgba(2,91,133,1) 50%,rgba(2,91,133,0.6) 100%)',
            borderBottom: 'rgba(2,91,133,0.8) solid 1px',
            zIndex: 100
        }">
            <div style="
                flex: 1 1 auto; 
                fontSize: 18px; 
                lineHeight: 46px; 
                letterSpacing: 5px;
                paddingLeft: 10px;
                line-height: 60px;
                color: rgba(255,255,255,0.8);
            ">
                <!-- <span style="display: inline-block; float: left; margin: 8px 10px 0 0;"><img  :src="logourl" width="26" /></span> -->
                <span style="display: inline-block; color: rgba(255,255,255,0.8);">舆情督办系统平台</span>
            </div>
            <div style="flex: 0 0 180px; lineHeight: 55px; color: rgba(255,255,255,0.8); ">
                <span><FreshTime/></span>
            </div>
            <div style="flex: 0 0 200px; textAlign: right; paddingRight: 20px;">
                <span style="width: 30px;height: 30px; background: rgba(0,0,0,0.1); display: inline-block;borderRadius: 30px;padding: 4px 7px; marginTop: 10px; color: rgba(1,254,252,0.8);">
                    <i class="el-icon-user-solid"></i>
                </span>
                <span>
                    <el-dropdown>
                        <span class="el-dropdown-link" style="cursor: pointer;  marginLeft: 5px; color:rgba(1, 254, 252, 0.8);">
                            {{username}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" class="backDropdown">
                            <el-dropdown-item @click.native="editPass">重置密码</el-dropdown-item>
                            <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </span>
            </div>
        </div>
        <div class="container_back" >
            <div class="cleft">
                <overlay-scrollbars :style="{height: windowH+'px'}">
                    <el-menu
                        :default-active="nav"
                        class="el-menu-vertical-demo"
                        :style="{height: windowH+'px'}"
                        @open="handleOpen"
                        @close="handleClose"
                        :unique-opened="true"
                        text-color="#ffffff"
                    >
                        <span v-for="item in routeList" :key="item.value">
                            <el-submenu v-if="item.children && item.children.length>0"  :index="item.value">
                                <template slot="title">
                                    <span style="marginRight: 10px;fill: #fff !important;"><v-icon :name="item.icon" /></span>
                                    <span>{{item.name}}</span>
                                </template>
                                <!---三级开始-->
                                <span v-for="subItem in item.children" :key="subItem.value" >
                                    <el-menu-item v-if="!subItem.children" :key="subItem.value" :index="subItem.value" @click="goto(subItem.value)">
                                        {{subItem.name}}
                                    </el-menu-item>
                                     <el-submenu v-if="subItem.children && subItem.children.length>0"  :index="subItem.value">
                                        <span slot="title">{{subItem.name}}</span>
                                        <el-menu-item v-for="grandSubItem in subItem.children" :key="grandSubItem.value" :index="grandSubItem.value" @click="goto(grandSubItem.value)">
                                            <span slot="title" >{{grandSubItem.name}}</span>
                                        </el-menu-item>
                                    </el-submenu>
                                </span>
                                <!--三级结束-->
                            </el-submenu>
                            <el-menu-item v-if="!item.children" :index="item.value" @click="goto(item.value)">
                                <span style="marginRight: 10px;fill: #fff !important;"><v-icon :name="item.icon" /></span>
                                <span slot="title" >{{item.name}}</span>
                            </el-menu-item>
                        </span>
                    </el-menu>
                </overlay-scrollbars>
            </div>
            <div :style="{height: windowH +'px'}" class="cright">
                <BreadNav />
                <router-view></router-view>
            </div>
        </div>
        <el-dialog
            title="重置密码"
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose"
        >
             <el-form  ref="ruleFormForPass" label-position="left" label-width="80px" :model="ruleForm" :rules="rules">
                <el-form-item  label="新密码" prop="password">
                    <el-input show-password v-model="ruleForm.password"></el-input>
                </el-form-item>
                <el-form-item label="重复密码" prop="password_repeat">
                    <el-input show-password v-model="ruleForm.password_repeat"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                
                <el-button show-password="true" @click="submitForm('ruleFormForPass')" type="primary">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import _ from 'lodash'
//import { decrypt } from '../utils/cryptoAES.js'
import { adminRouteList } from "../config/adminRouteList.js"
import FreshTime from '../freshTime/index.vue'
import BreadNav from './breadnav.vue'
import { wyAxiosPost } from '../utils/wyAxios'
import { jiami,jiema } from '../utils/jiema.js'
//这里为左侧导航识别用，只需记录左侧有显示的导航
const rList = [
    {path: "/sentiment/sentimentInfo", name: "首页"},
    {path: "/sentiment/sentimentInfo",name:"舆情信息"},
    {path: "/sentiment/task",name:"舆情任务"},
    {path:"/sentiment/system/department",name:"部门"},
    {path:"/sentiment/system/user",name:"用户"},
    {path:"/sentiment/system/role",name:"角色"}
];
export default {
    name: 'Admin',
    components:{
        FreshTime,
        BreadNav
    },
    data(){
        const validatePass = (rule, value, callback) => {
            if (value == '') {
                callback(new Error('请输入密码'));
            } else if (this.ruleForm.password_repeat != "" && value != this.ruleForm.password_repeat) {
                callback(new Error('两次输入密码不一致!'));
            }
            callback();
        };
        const validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleForm.password && this.ruleForm.password != "") {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
           nav: "",
           username:"",
           routeList: [],
           rel:[],
           dialogVisible: false,
           ruleForm:{
                password:"",
                password_repeat:"",
            },
            rules: {
                password:[
                    { required: true, message:'不能为空',trigger:"blur"},
                    { validator: validatePass, trigger: 'blur' }
                ],
                password_repeat:[
                    { required: true, message:'不能为空',trigger:"blur"},
                    { validator: validatePass2, trigger: 'blur' }
                ]
            },
            logourl: require('../assets/logo.png')
        }
        
    },
    methods:{
        editPass(){
           this.dialogVisible = true;
       },
       submitForm(formName){
           this.$refs[formName].validate((valid) => {
                if (valid) {
                    let token = jiema(localStorage.getItem("scxgo_token"));
                    const {id} = token;                    
                    const { password } = this.ruleForm;

                    const param = jiami(JSON.stringify({id,password}));
                    wyAxiosPost('/manage/user/save',{param},(res)=>{
                        const {code,msg} = res;
                        if(code && code == 1){
                            this.$message({
                                type:"success",
                                message: "密码已修改成功，请重新登录系统"
                            })
                            localStorage.removeItem("scxgo_token");
                            localStorage.removeItem("scxgo_user");
                            this.$router.push({path:"/login"});
                        }else{
                            this.$message.error(msg);
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
       },
       logout(){
           localStorage.removeItem("scxgo_token");
           localStorage.removeItem("scxgo_user");
           this.$router.push("/login");
       },
       goFrountstage(){
           this.$router.push("/app");
       },
       handleOpen(){
       },
       handleClose(){
           this.dialogVisible = false;
       },
       goto(path){
           this.$router.push(path);
       }
    },
    mounted(){
        // const jurisdiction = localStorage.getItem("jurisdiction");
        // const rel = JSON.parse(decrypt(jurisdiction));
        // this.rel = rel;

        //根据page过滤路由
        const curRouteList = _.cloneDeep(adminRouteList);
        //根据权限过滤
       let token = jiema(localStorage.getItem("scxgo_token"));
        const {all_auth} = token;
        const that = this;
        function filterRoute(arr){
            let newArr = arr.filter(a=>{
                if(a.children && a.children.length>0 && a.type === 'page'){
                    const curChildren = that.$lodash.cloneDeep(a.children);
                    let lala = filterRoute(curChildren);
                    if(lala && lala.length>0){
                        a.children = that.$lodash.cloneDeep(lala);
                    }else{
                        delete a.children;
                    }
                }
                return a.type === "page" && all_auth.indexOf(a.authId) != -1 && all_auth.indexOf(a.authId+'_self') != -1;
            })
            return newArr;
        }

        this.routeList = filterRoute(curRouteList);
        console.log(this.routeList)
        



        this.username = localStorage.getItem("scxgo_user");
        const routerList = rList;
        const curObj = _.find(routerList,o=>{
            return this.$route.path.indexOf(o.path) != -1;
        })

        if(curObj){
            this.nav = curObj.path;
        }
   
    },
    computed:{
        windowH:function(){
            return this.$store.state.windowH - 60;
        }
    },
    watch:{
        $route(to){
             // if(this.rel.indexOf(newVal) === -1){
            //     this.$router.push("/login");
            //     this.$message({
            //         type:"warnning",
            //         message:"您没有权限访问"
            //     })
            //     return;
            // }
            const routerList = rList;
            const curObj = _.find(routerList,o=>{
                //return o.path === to.path
                return to.path.indexOf(o.path) != -1;
            })
            if(curObj){
                this.nav = curObj.path;
            }
            // else{
            //     const curPath = this.$route.path;
            //     const resourceNav = curPath.substr(0, curPath.lastIndexOf("/"));
            //     this.nav = resourceNav;
            // }
           
        }
    }
}
</script>

<style>
    .header{
        width: 100%;
        height: 60px;
    }
    .container_back{
        width: 100%;
        display: flex;
    }
    .backDropdown{
        background: #ffffff;
        border: rgba(14, 16, 53) solid 1px;
        box-shadow: none;
    }
    .backDropdown .el-dropdown-menu__item{
        color: #333333;
    }
    .cleft{
        flex:  0 0  200px;
        background: rgb(86, 117, 241);
    }
    .cright{
        flex: 1 1 auto;
        padding: 0 20px 20px 20px;
        background: #efeff4;
    }
    .cleft .el-submenu .el-menu-item{
        height: 40px;
        line-height: 40px;
    }
    .cleft .el-menu-item{
        height: 40px;
        line-height: 40px;
    }
    .cleft .el-submenu__title{
        height: 40px;
        line-height: 40px;
    }
    .cleft .el-menu{

        /* background-color: rgba(0,0,0,0.8); */
        /* background-color: #0291cc; */
        background-color: #025B85;
    }
    .cleft .el-menu-item.is-active{
        background-color: rgba(255,255,255,0.8);
        color:#0291cc;
        
    }
    .cleft .el-menu-item.is-active:hover{
        background-color: #ffffff;
        opacity: 1;
    }
    .cleft .el-submenu__title i{
        color: #ffffff;
    }
    .cleft .el-menu-item:hover{
        background-color: rgba(255,255,255,0.2);
    }
    .cleft .el-submenu__title:hover{
        background-color: rgba(255,255,255,0.2);
    }
    .cleft .el-submenu__title{
        font-weight: bold;
    }
    .el-table__header{
        font-weight: bold;
        font-size: 14px;
    }
    p{
        word-wrap:break-word;
    }
 

</style>



