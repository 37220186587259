// const ip_addr = "scxgo.asuscomm.com";
//const ip_addr = "10.0.0.103"; //线上测试
//const ip_addr = "10.0.0.169"; //老李本机
//const ip_addr = "192.168.3.6"; //部署服务地址
//const ip_addr = document.location.hostname;
// const host = "http://"+ip_addr+":8985/yuqing"
//const host = "http://amr.szkedun.cn:8985/yuqing"
const host = "https://opinion-end.szkedun.cn/yuqing"
// const host = "http://192.168.3.136:8985/yuqing"
// const host = "http://192.168.1.104:8980/yuqing"
// const host = "http://121.201.107.121:18985/yuqing"




export { host };
