<template>
  <div class="statis-page-wrap">
    <div
      style="
        display: flex;
        line-height: 30px;
        border-bottom: rgba(204, 204, 204, 0.4) solid 1px;
        background: #ffffff;
        margin-bottom: 6px;
        padding: 6px 0 0px 10px;
        font-size: 14px;
      "
    >
      <div style="flex: 1 1 auto">
        <span>
          <!-- <el-input
                        placeholder="请输入检索内容"
                        prefix-icon="el-icon-search"
                        v-model="searchStr"
                        style="width: 200px;"
                        @input="doSearch"
                        :clearable=true
                        size="mini"
                    >
                    </el-input> -->
        </span>
        <span class="forSearch">
          时间范围：
          <el-date-picker
            v-model="searchTime"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期-不限"
            end-placeholder="结束日期-不限"
            value-format="yyyy-MM-dd"
            style="width: 280px"
            size="mini"
          >
          </el-date-picker>
        </span>
        <span class="forSearch">
          部门：
          <el-select
            v-model="searchDepartment"
            placeholder="请选择"
            multiple
            size="mini"
            style="width: 180px"
          >
            <el-option
              v-for="item in initDepartment"
              :key="item.id"
              :label="item.department"
              :value="item.id"
            ></el-option>
          </el-select>
        </span>
        <span class="forSearch">
          舆情类别：
          <el-select v-model="opinionCategory" multiple placeholder="请选择">
            <el-option
              v-for="item in opionCategory"
              :key="item.dict_key"
              :label="item.dict_value"
              :value="item.dict_key"
              ></el-option>
          </el-select>
        </span>
      </div>
      <div style="flex: 0 0 80px">
        <AuthFilter authId="3_1">
          <el-button @click="taskLoad" size="mini" type="primary" plain
            ><i class="el-icon-download"></i> 导出</el-button
          >
        </AuthFilter>
      </div>
    </div>
    <div style="background: #ffffff; padding-bottom: 10px">
      <div style="font-size: 12px; line-height: 40px; text-align: center">
        <span style="display: inline-block; margin: 0 10px 0 10px"
          >已下发舆情<span
            style="fontsize: 16px; fontweight: bold; color: #0291cc"
            >{{ office.issue ? office.issue : "--" }}</span
          >宗
        </span>
        <span style="display: inline-block; margin: 0 10px 0 10px"
          >已处理舆情<span
            style="font-size: 16px; font-weight: bold; color: #67c23a"
            >{{ office.complete ? office.complete : "--" }}</span
          >宗</span
        >
      </div>
      <div class="static-info-bar-wrap">
        <StaticBar :chartData="chartData"></StaticBar>
      </div>
      <overlay-scrollbars :style="{ height: windowH - 50 + 'px' }">
        <el-table
          :data="tableData"
          style="width: calc(100% - 20px)"
          :stripe="true"
          ref="multipleTable"
          :show-overflow-tooltip="true"
          @selection-change="handleSelectionChange"
        >
          >
          <!-- <el-table-column
                        width="50"
                        type="selection"
                        
                    >
                    </el-table-column> -->
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column prop="department" label="部门"> </el-table-column>
          <el-table-column prop="task_total" label="总任务数" sortable>
          </el-table-column>
          <el-table-column prop="task_complete" label="已处理任务数" sortable>
          </el-table-column>
          <el-table-column prop="task_unfilish" label="未处理任务数" sortable>
          </el-table-column>
          <!-- <el-table-column
                        prop="task_coop"
                        label="配合处理任务数"
                        sortable
                        
                    >
                    </el-table-column> -->
          <el-table-column
            prop="task_complete_coop"
            label="总任务处理量"
            sortable
          >
          </el-table-column>
          <el-table-column prop="info_count" label="上报舆情数" sortable>
          </el-table-column>
        </el-table>
      </overlay-scrollbars>
      <div style="padding: 10px 0 0 20px">
        <!-- <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageInfo.pageSize"
                    :hide-on-single-page=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total"
                    :page-count="pageInfo.pageCount"
                    :current-page="pageInfo.currentPage"
                >
                </el-pagination> -->
      </div>
    </div>
  </div>
</template>

<script>
import { wyAxiosPost } from '../utils/wyAxios'
import AuthFilter from '../components/authFilter.vue'
import { host } from '../config/host.js'
import { mapState } from "vuex";
import StaticBar from '../components/static-bar.vue';

export default ({
  name: "Statis",
  components: {
    AuthFilter,
    StaticBar
  },
  data () {
    return {
      tableData: [],
      ids: [],
      drawer: false,
      pageInfo: {
        pageSize: 10,
        total: 0, //总条目数
        pageCount: 0, //总页数
        currentPage: 1,
      },
      searchStr: "",  //搜索内容
      curId: "",
      visible: false,
      //搜索数据
      searchTime: [], //报送时间
      searchDepartment: [],//类型
      initDepartment: [],
      office: {},
      filterDepartment: [],
      opinionCategory: [],
      chartData: {
        xData: [],
        yData: []
      }
    }
  },
  mounted () {
    const { searchTime, searchDepartment } = this
    this.getTableData(searchTime, searchDepartment);
    //this.getExcuteDepartment();
  },
  methods: {
    //导出任务
    taskLoad () {

      this.$confirm('确定要导出统计列表吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const { searchDepartment, searchTime } = this;
        let startTime = "";
        let endTime = "";
        let strDepartment = "";
        if (searchDepartment && searchDepartment.length > 0) {
          strDepartment = JSON.stringify(searchDepartment);
        }
        const data = { searchDepartment: strDepartment };

        if (searchTime && searchTime.length > 0) {
          startTime = searchTime[0];
          endTime = searchTime[1];
          data.startTime = startTime;
          data.endTime = endTime;
        }
        wyAxiosPost('/statis/statisLoad', { ...data }, (res) => {
          const { code, data } = res;
          if (code === 1) {
            let DownloadLink = document.createElement('a');
            DownloadLink.style = 'display: none'; // 创建一个隐藏的a标签
            DownloadLink.download = "";
            DownloadLink.href = host + "/" + data.url;
            DownloadLink.target = "_blank";
            document.body.appendChild(DownloadLink);
            DownloadLink.click(); // 触发a标签的click事件
            document.body.removeChild(DownloadLink);
            this.cancelPop();
          }
        })

      })
    },
    cancelPop () {
      this.visible = false;
      this.ids = [];
    },

    filterTag (value, row) {
      return row.department === value;
    },
    //分页相关
    handleSizeChange (val) {
      const { theme_id } = this
      this.getTask(1, val, this.searchStr, theme_id, this.area);
      this.pageInfo.pageSize = val;
      this.pageInfo.currentPage = 1;
    },
    handleCurrentChange (val) {
      const { theme_id } = this
      this.getTask(val, this.pageInfo.pageSize, this.searchStr, theme_id, this.area);
      this.pageInfo.currentPage = val;
    },
    //搜索
    doSearch () {
      this.pageInfo.currentPage = 1;
      const { pageSize } = this.pageInfo;
      const { searchStr } = this
      this.getTableData(1, pageSize, searchStr);
    },
    //多选
    handleSelectionChange (val) {
      const ids = [];
      if (val && val.length > 0) {
        val.map(item => {
          ids.push(item.id)
        })
      }
      this.ids = ids;
    },

    showDrawer () {
      this.drawer = true;
    },

    //获取
    getTableData (searchTime, searchDepartment) {
      let startTime = "";
      let endTime = "";
      let strDepartment = "";
      let opinionCategory = this.opinionCategory.join();
      if (searchDepartment && searchDepartment.length > 0) {
        strDepartment = JSON.stringify(searchDepartment);
      }
      const data = { searchDepartment: strDepartment, opinionCategory };

      if (searchTime && searchTime.length > 0) {
        startTime = searchTime[0];
        endTime = searchTime[1];
        data.startTime = startTime;
        data.endTime = endTime;
      }

      wyAxiosPost('/statis/getList', { ...data }, (res) => {
        const { code, data } = res;
        if (code === 1) {
          this.office = data.office;
          this.tableData = data.list;
          let chartList = this.$lodash.cloneDeep(data.list);
          chartList.sort((a,b) => b.task_total - a.task_total);
          let xData = [];
          let yData = [];
          chartList.forEach(item => {
            xData.push(item.department);
            yData.push(item.task_total);
          });
          this.chartData = {
            xData,
            yData
          }
          if (data.list && data.list.length > 0 && this.initDepartment.length == 0) {
            this.initDepartment = this.$lodash.cloneDeep(data.list);
          }
        }
      })
    },
    //获取除了中心部门之外的所有其他部门
    getExcuteDepartment () {
      wyAxiosPost('/detail/getExecuteDepartment', {}, (res) => {
        const { code, data } = res
        if (code === 1) {
          this.initDepartment = data;
          if (data && data.length > 0) {
            const curData = this.$lodash.cloneDeep(data);
            const curFilter = curData.map(item => {
              return { text: item.name, value: item.name }
            })
            this.filterDepartment = curFilter;
          }
        }
      })
    },
  },
  computed: {
    ...mapState([
      "opionCategory"
    ]),
    windowH: function () {
      return this.$store.state.windowH - 170;
    }
  },
  watch: {
    searchTime () {
      const { searchTime, searchDepartment } = this
      this.getTableData(searchTime, searchDepartment);
    },
    searchDepartment () {
      const { searchTime, searchDepartment } = this
      this.getTableData(searchTime, searchDepartment);
    },
    opinionCategory() {
      const { searchTime, searchDepartment } = this
      this.getTableData(searchTime, searchDepartment);
    }
  }
})
</script>
<style>
.statis-page-wrap {
  height: calc(100% - 30px);
  overflow-y: auto;
}
.forbutton {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}
.forbutton:hover {
  color: #0055fe;
  font-weight: bold;
}
.forSearch {
  display: inline-block;
  margin-right: 20px;
}
.static-info-bar-wrap{
  width: 100%;
  height: 300px;
  margin: 16px 0;
}
</style>
