
import Login from '../login/index.vue'
import Regist from '../regist/index.vue'
import Admin from '../admin/index.vue'

import Home from '../home/index.vue'


import SentimentInfo from '../sentimentInfo/index.vue'
import AddInfo from '../sentimentInfo/addInfo.vue'
import Detail from '../sentimentInfo/detail.vue'

import Task from '../task/index.vue'
import TaskDetail from '../task/taskDetail.vue'

import Statis from '../statis/index.vue'



import Department from '../department/index.vue'
import User from '../user/index.vue'
import Role from '../role/index.vue'

export default [
    { path: '/',component: Login },
    { path: '/login',component: Login },
    { path: '/regist',component: Regist },
   
    {
        path: '/sentiment',component: Admin,
        children:[
            {path: '/sentiment/home',component: Home},

            {path: '/sentiment/sentimentInfo',component: SentimentInfo},
            {path: '/sentiment/sentimentInfo/addInfo',component: AddInfo},
            {path: '/sentiment/sentimentInfo/detail',component: Detail},

            {path: '/sentiment/task',component: Task},
            {path: '/sentiment/task/taskDetail',component: TaskDetail},


            {path: '/sentiment/statis',component: Statis},


            {path: '/sentiment/system/department',component: Department},
            {path: '/sentiment/system/user',component: User},
            {path: '/sentiment/system/role',component: Role},
            {path: '/sentiment/system',redirect: '/sentiment/system/department'}
        ]
    },

]