<template>
  <keep-alive>
    <div>
      <div
        style="
          display: flex;
          line-height: 30px;
          border-bottom: rgba(204, 204, 204, 0.4) solid 1px;
          background: #ffffff;
          margin-bottom: 6px;
          padding: 6px 0 0px 10px;
          font-size: 14px;
        "
      >
        <div style="flex: 1 1 auto">
          <span style="margin-right: 20px;">
            <el-input
              placeholder="请输入检索内容"
              prefix-icon="el-icon-search"
              v-model="searchStr"
              style="width: 160px"
              @input="doSearch"
              :clearable="true"
              size="mini"
            >
            </el-input>
          </span>
          <span class="forSearch">
            办结时限：
            <el-date-picker
              v-model="searchTime"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期-不限"
              end-placeholder="结束日期-不限"
              value-format="yyyy-MM-dd"
              style="width: 280px"
              size="mini"
            >
            </el-date-picker>
          </span>
          <span class="forSearch">
            级别：
            <el-select
              v-model="searchLevel"
              placeholder="请选择"
              size="mini"
              style="width: 80px"
            >
              <el-option value="" label="不限"></el-option>
              <el-option value="关注" label="关注"></el-option>
              <el-option value="核查" label="核查"></el-option>
            </el-select>
          </span>
          <span class="forSearch">
            状态：
            <el-select
              v-model="searchStatus"
              placeholder="请选择"
              size="mini"
              style="width: 120px"
            >
              <el-option value="" label="不限"></el-option>
              <el-option value="1" label="待签收"></el-option>
              <el-option value="2" label="待处置"></el-option>
              <el-option value="3" label="超时未处置"></el-option>
              <el-option value="4" label="待办结"></el-option>
              <el-option value="5" label="超时待办结"></el-option>
              <el-option value="6" label="已办结"></el-option>
              <el-option value="7" label="已移除"></el-option>
            </el-select>
          </span>
          <span class="forSearch" v-show="false">
            是否配合：
            <el-select
              v-model="searchCoop"
              placeholder="请选择"
              size="mini"
              style="width: 80px"
            >
              <el-option value="" label="不限"></el-option>
              <el-option value="true" label="是"></el-option>
              <el-option value="false" label="否"></el-option>
            </el-select>
          </span>
          <span class="forSearch">
            舆情类别：
            <el-select v-model="opinionCategory" multiple placeholder="请选择">
              <el-option
                v-for="item in opionCategory"
                :key="item.dict_key"
                :label="item.dict_value"
                :value="item.dict_key"
              ></el-option>
            </el-select>
          </span>
        </div>
        <div style="flex: 0 0 80px">
          <AuthFilter authId="2_1">
            <el-button @click="taskLoad" size="mini" type="primary" plain
              ><i class="el-icon-download"></i> 导出</el-button
            >
          </AuthFilter>
        </div>
      </div>
      <div style="background: #ffffff; padding-bottom: 10px" class="rowTable">
        <overlay-scrollbars :style="{ height: windowH - 40 + 'px' }">
          <el-table
            :data="tableData"
            style="width: calc(100% - 20px)"
            :stripe="true"
            ref="multipleTable"
            :show-overflow-tooltip="true"
            @selection-change="handleSelectionChange"
            @row-click="toDetail"
          >
            >
            <!-- <el-table-column
                        width="50"
                        type="selection"
                        
                    >
                    </el-table-column> -->
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="title" label="舆情标题"> </el-table-column>
            <el-table-column prop="department_name" label="部门">
            </el-table-column>
            <el-table-column prop="issue_time" label="下发时间">
            </el-table-column>
            <el-table-column prop="task_level" label="任务级别">
            </el-table-column>
            <!-- <el-table-column
                        prop="is_coop"
                        label="是否配合"
                        
                    >
                    </el-table-column> -->
            <el-table-column prop="plan_time" label="办结时限">
            </el-table-column>
            <el-table-column prop="sent_person" label="任务状态">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.current_status == 1"
                  >待签收</el-tag
                >
                <el-tag type="primary" v-if="scope.row.current_status == 2"
                  >待处置</el-tag
                >
                <el-tag type="primary" v-if="scope.row.current_status == 3"
                  >超时未处置</el-tag
                >
                <el-tag type="success" v-if="scope.row.current_status == 4"
                  >待办结</el-tag
                >
                <el-tag type="success" v-if="scope.row.current_status == 5"
                  >超时待办结</el-tag
                >
                <el-tag type="success" v-if="scope.row.current_status == 6"
                  >已办结</el-tag
                >
                <el-tag type="danger" v-if="scope.row.current_status == 7"
                  >已移除</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
        </overlay-scrollbars>
        <div style="padding: 10px 0 0 20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageInfo.pageSize"
            :hide-on-single-page="false"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total"
            :page-count="pageInfo.pageCount"
            :current-page="pageInfo.currentPage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </keep-alive>
</template>

<script>
import { wyAxiosPost } from '../utils/wyAxios'
import AuthFilter from '../components/authFilter.vue'
import { host } from '../config/host.js'
import { jiema } from '../utils/jiema.js'
import { mapState } from "vuex";


export default ({
  name: "Task",
  components: {
    AuthFilter
  },
  data () {
    return {
      opinionCategory: [],
      tableData: [],
      ids: [],
      drawer: false,
      pageInfo: {
        pageSize: 10,
        total: 0, //总条目数
        pageCount: 0, //总页数
        currentPage: 1,
      },
      searchStr: "",  //搜索内容
      curId: "",
      //搜索数据
      searchTime: "", //报送时间
      searchLevel: "",//类型
      searchStatus: "", //状态
      searchCoop: ""  //是否协同
    }
  },
  mounted () {
    if (sessionStorage.getItem("taskDetailBack")) {
      //如果有就读取缓存里面的数据
      let taskSearchStorage = JSON.parse(sessionStorage.getItem("taskSearchStorage"));
      this.$set(this.pageInfo, 'pageSize', taskSearchStorage.pageInfo.pageSize);
      this.$set(this.pageInfo, 'currentPage', taskSearchStorage.pageInfo.currentPage);
      this.$set(this.pageInfo, 'total', taskSearchStorage.pageInfo.total);
      this.searchTime = taskSearchStorage.searchTime;
      this.searchStr = taskSearchStorage.searchStr;
      this.searchLevel = taskSearchStorage.searchLevel;
      this.searchStatus = taskSearchStorage.searchStatus;
      this.opinionCategory = taskSearchStorage.opinionCategory;
    } else {
      //其他页面第一次进入列表页，清掉缓存里面的数据
      sessionStorage.removeItem("taskSearchStorage");
    }

    const { currentPage, pageSize } = this.pageInfo;
    const { searchStr, searchTime, searchLevel, searchStatus, searchCoop } = this
    this.getTableData(currentPage, pageSize, searchStr, searchTime, searchLevel, searchStatus, searchCoop);

    this.$watch('searchTime', this.searchByPageOne);
    this.$watch('searchLevel', this.searchByPageOne);
    this.$watch('searchStatus', this.searchByPageOne);
    this.$watch('searchCoop', this.searchByPageOne);
    this.$watch('opinionCategory', this.searchByPageOne);


  },
  destroyed () {
    // 销毁组件
    sessionStorage.removeItem("taskDetailBack");
  },
  methods: {
    searchByPageOne () {
      this.pageInfo.currentPage = 1;
      const { pageSize } = this.pageInfo;
      const { searchStr, searchTime, searchLevel, searchStatus, searchCoop } = this
      this.getTableData(1, pageSize, searchStr, searchTime, searchLevel, searchStatus, searchCoop);
    },

    //分页相关
    handleSizeChange (val) {
      this.pageInfo.currentPage = 1;
      this.pageInfo.pageSize = val;
      const { pageSize } = this.pageInfo;
      const { searchStr, searchTime, searchLevel, searchStatus, searchCoop } = this
      this.getTableData(1, pageSize, searchStr, searchTime, searchLevel, searchStatus, searchCoop);
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val;
      const { pageSize } = this.pageInfo;
      const { searchStr, searchTime, searchLevel, searchStatus, searchCoop } = this
      this.getTableData(val, pageSize, searchStr, searchTime, searchLevel, searchStatus, searchCoop);
    },
    //搜索
    doSearch () {
      this.pageInfo.currentPage = 1;
      const { pageSize } = this.pageInfo;
      const { searchStr, searchTime, searchLevel, searchStatus, searchCoop } = this
      this.getTableData(1, pageSize, searchStr, searchTime, searchLevel, searchStatus, searchCoop);
    },
    //多选
    handleSelectionChange (val) {
      const ids = [];
      if (val && val.length > 0) {
        val.map(item => {
          ids.push(item.id)
        })
      }
      this.ids = ids;
    },

    showDrawer () {
      this.drawer = true;
    },
    //获取
    getTableData (pageNum, pageSize, searchStr, searchTime, searchLevel, searchStatus, searchCoop) {
      let opinionCategory = this.opinionCategory.join();
      let strSearchTime = "";
      if (searchTime && searchTime.length > 0) {
        strSearchTime = JSON.stringify(searchTime);
      }
      wyAxiosPost('/task/getList', { pageNum, pageSize, searchStr, searchTime: strSearchTime, searchLevel, searchStatus, searchCoop, opinionCategory }, (res) => {
        const { code, data } = res;
        if (code === 1) {
          this.tableData = data.list;
          this.pageInfo.total = data.total;
        }
      })
    },
    //跳转到详情页面
    toDetail (row) {
      const { id } = row;
      //判断权限
      const token = localStorage.getItem('scxgo_token');
      const rel = jiema(token);
      const { all_auth } = rel;
      const { current_status } = row;
      if (current_status == 7) {
        this.$message.warning("抱歉，此条任务已移除");
        return;
      }
      if (all_auth.indexOf('2_2_self') != -1) {
        let taskSearchStorage = {
          pageInfo: this.pageInfo,
          searchStr: this.searchStr,
          searchTime: this.searchTime,
          searchLevel: this.searchLevel,
          searchStatus: this.searchStatus,
          opinionCategory: this.opinionCategory
        }
        sessionStorage.setItem("taskSearchStorage", JSON.stringify(taskSearchStorage));
        this.$router.history.push({
          path: "/sentiment/task/taskDetail",
          query: {
            id: id
          }
        });
      } else {
        return;
        // this.$message.warning("抱歉，您没有详情的访问权限");
      }

    },
    cancelPop () {
      this.visible = false;
      this.ids = [];
    },
    //导出任务
    taskLoad () {
      this.$confirm('确定要导出任务列表吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const { searchStr, searchTime, searchLevel, searchStatus, searchCoop } = this
        wyAxiosPost('/task/taskLoad', { searchStr, searchTime, searchLevel, searchStatus, searchCoop }, (res) => {
          const { code, data } = res;
          if (code === 1) {
            let DownloadLink = document.createElement('a');
            DownloadLink.style = 'display: none'; // 创建一个隐藏的a标签
            DownloadLink.download = "";
            DownloadLink.href = host + "/" + data;
            DownloadLink.target = "_blank";
            document.body.appendChild(DownloadLink);
            DownloadLink.click(); // 触发a标签的click事件
            document.body.removeChild(DownloadLink);
            this.cancelPop();
          }
        })
      })
    },
  },
  computed: {
    ...mapState([
      "opionCategory"
    ]),
    windowH: function () {
      return this.$store.state.windowH - 170;
    }
  },
})
</script>
<style>
.forbutton {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}
.forbutton:hover {
  color: #0055fe;
  font-weight: bold;
}
.forSearch {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 8px;
}
.rowTable .el-table tbody tr:hover > td {
  cursor: pointer;
  background-color: rgba(2, 145, 204, 0.2) !important;
}
</style>
