import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import 'element-ui/lib/theme-chalk/base.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import "overlayscrollbars/css/OverlayScrollbars.css";
import OverlayScrollbars from "overlayscrollbars";
import { OverlayScrollbarsPlugin } from "overlayscrollbars-vue";
//import VueCropper from 'vue-cropper'
import 'vue-awesome/icons'
import IconFontAwesome  from 'vue-awesome/components/Icon'
import axios from 'axios'
import qs from 'qs'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'




import Vue2OrgTree from 'vue2-org-tree'
import 'vue2-org-tree/dist/style.css'

import 'element-ui/lib/theme-chalk/index.css';
import routes from './routes/index.js'
import stores from './store/index.js'
import "../theme/index.css"
import { jiema } from './utils/jiema.js'
import { breadNav } from './config/adminRouteList.js'

import _ from 'lodash'
import {
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Popover,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Tree,
  Alert,
  Slider,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Spinner,
  Badge,
  Card,
  Rate,
  Steps,
  Step,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Cascader,
  ColorPicker,
  Transfer,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Timeline,
  TimelineItem,
  Link,
  Divider,
  Image,
  Calendar,
  Backtop,
  PageHeader,
  CascaderPanel,
  Loading,
  MessageBox,
  Message,
  Notification,
  Popconfirm,
  Avatar,
  Drawer
} from 'element-ui';

Vue.use(Popconfirm);
Vue.use(Avatar);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Drawer);
Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Tree);
Vue.use(Alert);
Vue.use(Slider);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Spinner);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Rate);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cascader);
Vue.use(ColorPicker);
Vue.use(Transfer);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Link);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Calendar);
Vue.use(Backtop);
Vue.use(PageHeader);
Vue.use(CascaderPanel);



Vue.use(Loading.directive);

Vue.use(Viewer)
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.component(CollapseTransition.name, CollapseTransition)
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };

Vue.prototype.$axios = axios    //全局注册，使用方法为:this.$axios
Vue.prototype.qs = qs           //全局注册，使用方法为:this.qs
Vue.prototype.$lodash = _; 
Vue.use(OverlayScrollbarsPlugin);
Vue.use(OverlayScrollbars);
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(Vue2OrgTree)

Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})
Vue.component('v-icon', IconFontAwesome)
const store = new Vuex.Store(stores);
Vue.config.productionTip = false

const router =  new VueRouter({
  mode: 'history',
  base: '',
  routes:routes
})
Vue.prototype.$myRoute = router;
//路由拦截
router.beforeEach((to, from, next) => {
  if(to.path === "/login" || to.path === "/"){
    next()
    return;
  }else{
    let token = jiema(localStorage.getItem("scxgo_token"));
    console.log(token)
    const all_auth = token.all_auth;
    const target = _.find(breadNav,o=>{
      return o.path == router.history.pending.path;
    })
    const { authId } = target
    const isExist = all_auth.indexOf(authId) != -1;
    if(isExist){
      next();
    }else{
      localStorage.removeItem("scxgo_token");
      localStorage.removeItem("scxgo_user");
      Message({
        type:"warning",
        message:"抱歉，您没有访问权限(1.您没有登陆；2.被修改了访问权限；3.同台主机有其他账号登陆，您被迫下线；)"
      });
      next({ path: "/login" });
    }
  }
});

new Vue({
  router,
  store,
  render: h=>h(App)
}).$mount('#app')



