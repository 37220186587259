import "axios";
import axios from "axios";
import qs from "qs";
import { host } from '../../config/host.js'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//axios.defaults.headers.post['Content-Type'] = 'application/json';


const wyAxiosPost = function(url,data,callback){
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    let token = localStorage.getItem("scxgo_token");
    if(token){
        axios.defaults.headers.post['Authorization'] = token;
    }
    const relUrl = host + url;
    const relData =  qs.stringify(data);
    axios.post(relUrl,relData).then(function(res){
        if(res.status === 200){
            const result = res.data
            if(result.code && result.code == 4){
                localStorage.removeItem("scxgo_token");
                localStorage.removeItem("scxgo_user");
                //http://amr.szkedun.cn/login
                window.location.href = "http://amr.szkedun.cn/login";
                alert("抱歉，该用户已不存在");
                return;
            }else{
                 callback(res.data)
            }
            
        }else{
            console.log("请求错误");
        }
    }).catch(function(error){
        console.log(error)
    })
}

const wyAxios_formData = function(url,data,callback){
    const relUrl = host + url;
    axios({
        method: 'post',
        heards:{
            'Content-Type':"multipart/form-data"
        },
        url:relUrl,
        data:data,
    }).then(function(res){
        if(res.status === 200){
            callback(res.data);
        }else{
            console.log("请求错误");
        }
    }).catch(function(error){
        console.log(error);
    });
}

const wyAxiosGet = function(url,data,callback){
  let token = localStorage.getItem("scxgo_token");
  if(token){
      axios.defaults.headers.post['Authorization'] = token;
  }
  const relUrl = host + url;
  axios({
    url: relUrl,
    params: {
      ...data
    }
  }).then(function(res){
      if(res.status === 200){
          const result = res.data
          if(result.code && result.code == 4){
              localStorage.removeItem("scxgo_token");
              localStorage.removeItem("scxgo_user");
              window.location.href = "http://amr.szkedun.cn/login";
              alert("抱歉，该用户已不存在");
              return;
          }else{
               callback(res.data)
          }
      }else{
          console.log("请求错误");
      }
  }).catch(function(error){
      console.log(error)
  })
}



export {wyAxiosPost,wyAxios_formData,wyAxiosGet}