<template>
  <div>
    <div
      style="
        display: flex;
        line-height: 30px;
        border-bottom: rgba(204, 204, 204, 0.4) solid 1px;
        padding-bottom: 10px;
        background: #ffffff;
        margin-bottom: 6px;
        padding: 6px 0 8px 10px;
      "
    >
      <div style="flex: 1 1 auto; font-size: 14px">
        <span @click="toSentimentInfo" class="forbutton" style="display: flex;align-items: center">
          <v-icon name="angle-left" /> 
          <span style="margin-left: 6px">返回舆情列表</span>
        </span>
      </div>
      <div
        style="
          flex: 0 0 310px;
          text-align: right;
          padding-right: 20px;
          font-size: 14px;
        "
      ></div>
    </div>
    <div style="background: #ffffff; padding: 60px 40px 10px 60px">
      <div style="width: 800px">
        <overlay-scrollbars :style="{ height: windowH - 120 + 'px' }">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="" prop="sentiment_type">
              <el-radio-group
                v-model="ruleForm.sentiment_type"
                @input="opionTypeChange"
              >
                <el-radio v-for="item in taskCategory" :key="item.dict_key" :label="item.dict_key">{{item.dict_value}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="舆情标题" prop="title">
              <el-input v-model="ruleForm.title"></el-input>
            </el-form-item>
            <el-form-item label="上报单位" prop="up_department">
              <el-select v-model="ruleForm.up_department" placeholder="请选择">
                <el-option
                  v-if="!up_department_obj.name"
                  label="无"
                  value=""
                ></el-option>
                <el-option
                  v-if="up_department_obj.name"
                  :key="up_department_obj.id"
                  :label="up_department_obj.name"
                  :value="up_department_obj.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上传附件" prop="enclosure">
              <el-upload
                class="upload-demo"
                ref="upload"
                :action="uploadUrl"
                :on-remove="handleRemove"
                :file-list="fileList"
                :auto-upload="true"
                :before-upload="beforeUpload"
                :headers="header"
                :multiple="false"
                :on-success="fileSuccess"
              >
                <el-button slot="trigger" size="small" type="primary"
                  >选取文件</el-button
                >

                <div slot="tip" class="el-upload__tip">
                  请上传zip或rar压缩文件，且不超过50M
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="舆情内容" prop="content">
              <editor v-model="ruleForm.content" id="tinymce" :init="init">
              </editor>
            </el-form-item>
            <div style="display: flex">
              <div style="flex: 0 0 33%">
                <el-form-item label="信息来源" prop="info_resource">
                  <el-select
                    v-model="ruleForm.info_resource"
                    placeholder="请选择"
                  >
                    <el-option
                      label="市委网信办转办"
                      value="市委网信办转办"
                    ></el-option>
                    <el-option
                      label="上级部门转办"
                      value="上级部门转办"
                    ></el-option>
                    <el-option
                      label="局领导批示"
                      value="局领导批示"
                    ></el-option>
                    <el-option label="自主监测" value="自主监测"></el-option>
                    <el-option label="其它" value="其它"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div style="flex: 0 0 34%">
                <el-form-item
                  label="原文链接"
                  label-width="110px"
                  prop="resource_link"
                >
                  <el-input v-model="ruleForm.resource_link"></el-input>
                </el-form-item>
              </div>
              <div style="flex: 0 0 33%">
                <el-form-item label="发布媒体" prop="author">
                  <el-input v-model="ruleForm.author"></el-input>
                </el-form-item>
              </div>
              <!-- <div style="flex: 0 0 33%">
                                <el-form-item label="文章类型" prop="info_type">
                                    <el-select v-model="ruleForm.info_type" placeholder="请选择">
                                        <el-option label="新闻" value="新闻"></el-option>
                                        <el-option label="论坛" value="论坛"></el-option>
                                        <el-option label="微博" value="微博"></el-option>
                                        <el-option label="微信" value="微信"></el-option>
                                        <el-option label="博客" value="博客"></el-option>
                                        <el-option label="其他" value="其他"></el-option>
                                    </el-select>
                                </el-form-item> 
                            </div> -->
            </div>
            <div style="display: flex">
              <!-- <div style="flex: 0 0 34%">
                                <el-form-item label="原文发表时间" label-width="110px" prop="publish_time">
                                    <el-date-picker
                                        v-model="ruleForm.publish_time"
                                        type="date"
                                        placeholder="选择时间"
                                        style="width: 100%"
                                        value-format="yyyy-MM-dd"
                                    >
                                </el-date-picker>
                                </el-form-item> 
                            </div> -->
              <div style="flex: 0 0 33%"></div>
            </div>
            <el-form-item label="转办情况" prop="turn_situation" class="is-required">
              <el-input
                type="textarea"
                :rows="2"
                v-model="ruleForm.turn_situation"
              ></el-input>
            </el-form-item>
            <div style="display: flex">
              <div style="flex: 0 0 33%">
                <el-form-item label="舆情类别" prop="opinion_category" class="is-required">
                  <el-select v-model="ruleForm.opinion_category" placeholder="请选择">
                    <el-option
                      v-for="item in opionCategory"
                      :key="item.dict_key"
                      :label="item.dict_value"
                      :value="item.dict_key"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div style="flex: 0 0 34%">
                <el-form-item label="其他标签" label-width="110px" prop="other_label">
                  <el-input v-model="ruleForm.other_label"></el-input>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </overlay-scrollbars>
      </div>
      <div style="line-height: 60px; padding-left: 100px">
        <el-button type="primary" @click="doSave('ruleForm')">
          提交舆情
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { wyAxiosPost } from '../utils/wyAxios';
import axios from 'axios'
import tinymce from "tinymce";
import Editor from '@tinymce/tinymce-vue';
import 'tinymce/themes/silver/theme';
import "tinymce/plugins/image";
import "tinymce/plugins/link";
import "tinymce/plugins/code";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
//import 'tinymce/plugins/paste'; 粘贴图片插件
import { host } from '../config/host.js';
import { mapState } from "vuex";
import { dictTranslate } from '../utils/tool.js'


const dataModel = {
  title: "",
  content: "",
  info_resource: "",
  resource_link: "",
  author: "",
  up_department: {},
  turn_situation: "",
  opinion_category: "",
  other_label: "",
  sentiment_type: ""
};
export default ({
  name: "SentimentInfo",
  components: {
    'editor': Editor
  },
  data () {
    return {
      //附件上传
      header: {},
      file_url: "",
      fileList: [

      ],
      up_department_obj: {},
      //附件上传路径
      uploadUrl: host + "/addInfo/saveFile",
      searchStr: "",  //搜索内容
      ruleForm: this.$lodash.cloneDeep(dataModel),
      curId: "",
      rules: {
        title: [
          { required: true, message: "不能为空", trigger: 'blur' }
        ],
        // up_department:[
        //     { required: true, message:"不能为空",trigger:'blur'}
        // ],
        content: [
          { required: true, message: "不能为空", trigger: 'blur' }
        ],
        info_resource: [
          { required: true, message: "不能为空", trigger: 'blur' }
        ],
        resource_link: [
          { required: true, message: "不能为空", trigger: 'blur' }
        ],

        author: [
          { required: true, message: "不能为空", trigger: 'blur' }
        ],
        sentiment_type: [
          { required: true, message: "请选择", trigger: 'blur' }
        ],
      },
      taskType: "",
      up_department: {},


      //搜索数据
      searchTime: "", //报送时间
      searchType: "",//类型
      searchStatus: "", //状态

      //富文本配置
      init: {
        selector: "#tinymce", //tinymce的id
        language_url: "/static/tinymce/langs/zh_CN.js",
        content_style: "img{max-width: 100%}", //宽度限制
        language: "zh_CN",
        skin_url: "/static/tinymce/skins/ui/oxide", //编辑器需要一个skin才能正常工作，所以要设置一个skin_url指向之前复制出来的skin文件
        base_url: "/static/tinymce",
        plugins: "image link code table lists wordcount",//引入插件
        font_formats: "微软雅黑=Microsoft YaHei,Helvetica Neue;PingFang SC;sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun;serifsans-serif;Terminal=terminal;monaco;Times New Roman=times new roman;times", //字体
        fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px 50px 56px 60px 64px',//字体列表修改
        toolbar: "fontselect fontsizeselect link lineheight forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | image quicklink h2 h3 blockquote table numlist bullist preview fullscreen", //工具栏
        menubar: false, // 隐藏最上方menu
        // 图片上传三个参数，图片数据，成功时的回调函数，失败时的回调函数
        images_upload_handler: function (blobInfo, success, failure) {
          let formData = new FormData();
          console.log(blobInfo);
          formData.append("image", blobInfo.blob());
          console.log(blobInfo.blob());
          //uploadImg是上传图片的请求接口
          function uploadImg () {
            axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
            let token = localStorage.getItem("scxgo_token");
            if (token) {
              axios.defaults.headers.post['Authorization'] = token;
            }
            const url = host + "/addInfo/saveImg"
            return new Promise(function (resolve, reject) {
              axios({
                method: 'POST',
                url,
                data: formData
              }).then((res) => {
                const { code, data, msg } = res.data
                if (code === 1) {
                  const url = host + "/" + data.url
                  resolve(url);
                } else {
                  reject(msg);
                }

              }).catch(error => {
                console.log("系统级别错误")
                reject(error);
              })
            })
          }
          uploadImg()
            .then(res => {
              console.log(res);
              //success("https://qnsjk.huabeisky.com/" + res.data);
              success(res);
            })
            .catch(res => {
              console.log(res)
              failure("error");
            });
        }
      },
    }
  },
  mounted () {

    //获取参数query
    const { id } = this.$route.query
    this.haveHeader();
    if (id) {
      this.curId = id;
      this.getInfo(id);
    } else {
      this.ruleForm = this.$lodash.cloneDeep(dataModel);
      this.file_url = "";
      this.fileList = [];
      this.$refs.ruleForm.resetFields();
    }
    this.getUpDepartment();
    tinymce.init({});
  },
  methods: {
    opionTypeChange (label) {
      let preText = "【" + dictTranslate(label,this.taskCategory) + "】";
      const index = this.ruleForm.title.indexOf("】");
      if (index !== -1) {
        this.ruleForm.title = this.ruleForm.title.slice(index + 1); // 加1是为了删除包括要删除字符本身
      }
      this.ruleForm.title = preText + this.ruleForm.title;
    },
    getUpDepartment () {
      wyAxiosPost('/addInfo/getUpDepartment', {}, (res) => {
        const { code, data } = res;
        if (code == 1) {
          if (data && data.name) {
            this.ruleForm.up_department = data.id;
            this.up_department_obj = data;
          } else {
            this.ruleForm.up_department = "";
            this.up_department_obj = {};
          }
        }
      })
    },
    haveHeader () {
      let token = localStorage.getItem("scxgo_token");
      if (token) {
        this.header = { Authorization: token };
      }
    },
    //附件上传之前控制
    beforeUpload (file) {
      var FileExt = file.name.replace(/.+\./, "");
      const isFit = ['zip', 'rar'].indexOf(FileExt.toLowerCase()) === -1;
      if (isFit) {
        this.$message({
          type: 'warning',
          message: '请上传后缀名为zip或rar的附件！'
        });
        return false;
      }
      const isLt2M1 = file.size / 1024 / 1024 < 50;
      if (!isLt2M1) {
        this.$message({
          message: "上传文件大小不能超过50M"
        })
        return false;
      }
      if (this.fileList && this.fileList.length > 0) {
        this.$message({
          message: "只能上传一个附件"
        })
        return false;
      }
      return true;
    },
    fileSuccess (resource, file, fileList) {
      const { code, data } = resource;
      if (code === 1) {
        this.file_url = host + "/" + data.url
        this.fileList = fileList;
      }
    },
    handleRemove () {
      this.file_url = "";
      this.fileList = [];
    },
    //获取当前舆情信息
    getInfo (id) {
      wyAxiosPost('/addInfo/getInfo', { id }, (res) => {
        const { code, data } = res;
        if (code == 1) {
          const { title, content, info_resource, resource_link, author,  turn_situation, opinion_category, other_label, sentiment_type } = data;
          this.ruleForm = { title, content, info_resource, resource_link, author,turn_situation,opinion_category,other_label,sentiment_type };
          this.file_url = data.file_url;
          const curUrl = data.file_url;
          const startIndex = curUrl.lastIndexOf("/");
          const fileName = curUrl.substring(startIndex + 1);
          this.fileList = [{ name: fileName }];
        }
      })
    },
    //保存
    doSave (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = this.ruleForm
          const { file_url } = this;
          if (this.curId) {
            data.id = this.curId;
          }
          if (file_url && file_url.indexOf(this.fileList[0]['name'] === -1)) {
            data.file_url = file_url;
          }
          if (data.content == "") {
            this.$message.warning("内容不能为空");
            return;
          }
          console.log('data', data);
          wyAxiosPost('/addInfo/saveInfo', { ...data }, (res) => {
            const { code, msg } = res
            if (code === 1) {
              this.$message({
                type: 'success',
                message: msg
              })
              this.ruleForm = this.$lodash.cloneDeep(dataModel);
              this.$refs.ruleForm.resetFields();
              this.file_url = "";
              this.fileList = [];
              this.$router.history.push("/sentiment/sentimentInfo");
            } else {
              this.$message({
                type: 'warning',
                message: msg
              })
            }
          })
        } else {
          return false;
        }
      });
    },
    //跳转到舆情信息页面
    toSentimentInfo () {
      this.ruleForm = this.$lodash.cloneDeep(dataModel);
      this.file_url = "";
      this.fileList = [];
      this.$refs.ruleForm.resetFields();
      this.$router.history.push("/sentiment/sentimentInfo");
    },
  },
  computed: {
    ...mapState([
      "opionCategory",
      "taskCategory"
    ]),
    windowH: function () {
      return this.$store.state.windowH - 170;
    }
  },
  watch: {
  }
})
</script>
<style>
.forbutton {
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}
.forbutton:hover {
  color: #0055fe;
  font-weight: bold;
}
.tox-statusbar__branding {
  display: none;
}
</style>
