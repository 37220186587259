<template>
  <div style="display: flex">
    <div class="wc" style="flex: 0 0 240px">
      <div>
        <div class="in-t" style="background: #ffffff">舆情情况</div>
        <div style="background: #ffffff; margin-bottom: 10px">
          <overlay-scrollbars :style="{ height: '240px', width: '240px' }">
            <div class="le_out">
              <div class="le_left">下发人员：</div>
              <div class="le_right">{{ info.issue_user_name }}</div>
            </div>
            <div class="le_out">
              <div class="le_left">舆情状态：</div>
              <div class="le_right">
                {{ info.current_status }}
              </div>
            </div>
            <div class="le_out">
              <div class="le_left">办结时间：</div>
              <div class="le_right">{{ info.plan_time }}</div>
            </div>
            <div class="le_out">
              <div class="le_left">舆情等级：</div>
              <div class="le_right">{{ info.task_level }}</div>
            </div>
            <div class="le_out">
              <div class="le_left">办理部门：</div>
              <div
                :title="com_execute_department"
                style="flex: 1 1 auto; word-wrap: break-word"
              >
                {{ com_execute_department }}
              </div>
            </div>
            <div class="le_out" v-show="false">
              <div class="le_left">配合部门：</div>
              <div
                :title="com_coop_department"
                style="flex: 1 1 auto; word-wrap: break-word"
              >
                {{ com_coop_department }}
              </div>
            </div>
          </overlay-scrollbars>
        </div>
        <div class="in-b" style="background: #ffffff">
          <div class="in-t" style="background: #ffffff">处理情况</div>
          <overlay-scrollbars :style="{ height: windowH - 310 + 'px' }">
            <div
              v-for="(item, index) in initTaskList"
              :key="index"
              style="margin-bottom: 20px"
            >
              <div class="le_out" style="padding-left: 20px">
                <span style="font-weight: bold">{{ item.department_name }}</span>
              </div>
              <div class="le_out">
                <div class="le_left">处理人员：</div>
                <div class="le_right">{{ item.user_name }}</div>
              </div>
              <div class="le_out">
                <div class="le_left">处理状态：</div>
                <div class="le_right">{{ item.current_status }}</div>
              </div>
              <div class="le_out">
                <div class="le_left">处理时间：</div>
                <div class="le_right">{{ item.current_operate_time }}</div>
              </div>
            </div>
          </overlay-scrollbars>
        </div>
      </div>
    </div>
    <div class="wc" style="flex: 1 1 auto">
      <div class="in">
        <div class="in-t" style="display: flex">
          <div style="flex: 0 0 160px">
          <span @click="toSentimentInfo" class="forbutton" style="display: flex;align-items: center">
            <v-icon name="angle-left" /> 
            <span style="margin-left: 6px">返回舆情列表</span>
          </span>
          </div>
          
          
          <!-- <div v-if="userType == 1" style="flex: 1 1 auto; textAlign: right; "> -->
          <div style="flex: 1 1 auto; text-align: right">
            <AuthFilter authId="1_4_1">
              <span
                v-if="info.current_status == '未下发'"
                class="forbutton"
                @click="doSent('first')"
              >
                <i class="el-icon-position"></i>下发舆情</span
              >
            </AuthFilter>
            <AuthFilter authId="1_4_2">
              <span
                v-if="info.current_status == '已下发'"
                class="forbutton"
                @click="doSent('nofirst')"
              >
                <i class="el-icon-plus"></i>追加下发</span
              >
            </AuthFilter>
            <AuthFilter authId="1_4_3">
              <span
                v-if="info.current_status == '已下发'"
                class="forbutton"
                @click="taskOver"
              >
                <i class="el-icon-time"></i>舆情办结</span
              >
            </AuthFilter>
            <!-- <AuthFilter authId="1_4_4">
                            <span v-if="info.current_status == '已下发'" class="forbutton" @click="cancelSent"> <i class="el-icon-wind-power"></i>移除下发</span>
                        </AuthFilter> -->
            <!-- <span class="forbutton" @click="doSent('first')"> <i class="el-icon-position"></i>下发舆情</span>
                        <span class="forbutton" @click="doSent('nofirst')"> <i class="el-icon-plus"></i>追加下发</span>
                        <span class="forbutton" @click="taskOver"> <i class="el-icon-time"></i>舆情办结</span>
                        <span class="forbutton" @click="cancelSent"> <i class="el-icon-wind-power"></i>移除下发</span> -->
          </div>
        </div>
        <div class="in-b">
          <div style="border-bottom: solid #efeff4 1px; padding-bottom: 8px">
            <div
              style="
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                line-height: 60px;
                height: 60px;
              "
            >
              {{ info.title }}
            </div>
            <div style="display: flex; font-size: 12px">
              <div style="flex: 0 0 200px; padding-left: 20px">
                信息来源：{{ info.info_resource }}
              </div>
              <div style="flex: 1 1 auto; text-align: right; padding-right: 20px">
                <span class="subInfo">发布媒体：{{ info.author }}</span>
                <!-- <span class="subInfo">文章类型：{{info.info_type}}</span> -->
                <span class="subInfo">报送时间：{{ info.report_time }}</span>
              </div>
            </div>
          </div>
          <!-- <overlay-scrollbars 
                        ref="test" 
                        :options="options"
                        :style="{height: windowH-104 +'px'}"
                        
                    >    -->
          <div
            class="outContent"
            :style="{
              height: windowH - 104 + 'px',
              overflow: 'auto',
              lineHeigh: '30px',
            }"
          >
            <div style="margin: 0 auto">
              <div
                style="
                  height: auto;
                  padding: 40px;
                  font-size: 14px;
                  word-break: break-word;
                "
                v-html="info.content"
              ></div>
            </div>
            <div style="padding: 20px 0 0 40px;font-size: 12px">
              <span class="subInfo"
                >原文链接：<a target="_blank" :href="info.resource_link">{{
                  info.resource_link
                }}</a></span
              >
            </div>
            <div style="padding: 20px 0 0 40px;font-size: 12px">
              <span class="subInfo">转办情况：{{ info.turn_situation || '无' }}</span>
            </div>
            <div style="display: flex;padding: 20px 0 0 40px;font-size: 12px">
              <div style="flex: 0 0 300px;">
                舆情类别：{{ getOpinionTypeName() || '无' }}
              </div>
              <div style="flex: 1 1 auto; padding-right: 20px">
                其他类别：{{ info.other_label || '无' }}
              </div>
            </div>
            <div
              v-if="info.file_url"
              style="padding: 20px 0 0 40px; font-size: 12px"
            >
              <span class="subInfo"
                >附件：<a
                  style="cursor: pointer"
                  download
                  :href="info.file_url"
                  target="_blank"
                  >下载</a
                >
              </span>
            </div>
            <div style="padding: 20px">
              <div
                v-for="(item, index) in contact"
                :key="index"
                style="
                  display: flex;
                  border-top: #cccccc solid 1px;
                  border-bottom: #cccccc solid 1px;
                "
              >
                <div
                  style="
                    flex: 0 0 160px;
                    min-height: 100px;
                    font-size: 12px;
                    padding: 20px;
                    background: #f9f9f9;
                  "
                >
                  <div style="line-height: 30px">
                    发送人：{{ item.send_user_name }}
                  </div>
                  <div style="line-height: 30px">
                    所属部门：{{ item.send_department_name }}
                  </div>
                  <div style="line-height: 30px">消息类型：{{ item.type }}</div>
                </div>
                <div
                  style="
                    flex: 1 1 auto;
                    border-left: #cccccc solid 1px;
                    min-height: 100px;
                  "
                >
                  <div
                    style="
                      height: 30px;
                      line-height: 30px;
                      text-align: right;
                      padding-right: 30px;
                      font-size: 12px;
                    "
                  >
                    <span
                      style="display: inline-block; margin-right: 40px"
                      v-if="item.recieve_department_name"
                      >接收部门：{{ item.recieve_department_name }}</span
                    >
                    <span>时间：{{ item.send_time }}</span>
                  </div>
                  <div
                    style="padding: 20px; font-size: 14px"
                    v-html="item.content"
                  ></div>

                  <div
                    v-if="item.file_url"
                    style="
                      height: 30px;
                      line-height: 30px;
                      padding-right: 30px;
                      font-size: 12px;
                      padding-left: 20px;
                    "
                  >
                    <a
                      download
                      :href="item.file_url"
                      target="_blank"
                      style="color: #0055fe; cursor: pointer"
                      >附件下载</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- 拟办意见内容 -->
            <div style="padding: 20px" v-if="info.opinion">
              <h3>拟办意见</h3>
              <div style="padding: 20px">{{ info.opinion }}</div>
            </div>
          </div>
          <!-- </overlay-scrollbars> -->
        </div>
      </div>
      <span @click="goTop" class="upButton" v-if="backTop"
        ><i class="el-icon-caret-top"></i>UP</span
      >
    </div>
    <el-drawer
      title="下发舆情"
      :visible.sync="drawer"
      :before-close="handleClose"
      :size="600"
      v-loading="loading"
    >
      <overlay-scrollbars
        ref="userManageComponentRef"
        :style="{
          height: windowH + 24 + 'px',
          width: '100%',
          paddingRight: '60px',
        }"
        :overflowBehavior="{ x: 'hidden', y: 'scroll' }"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="任务级别" prop="task_level">
            <el-select v-model="ruleForm.task_level" placeholder="请选择">
              <el-option value="关注" label="关注"></el-option>
              <el-option value="核查" label="核查"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="办理部门" prop="execute_department">
            <el-select
              v-model="ruleForm.execute_department"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in cur_execute_department"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-show="false" label="配合部门" prop="coop_department">
            <el-select
              v-model="ruleForm.coop_department"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in cur_coop_department"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="通知方式" prop="note_method">
            <el-select
              v-model="ruleForm.note_method"
              multiple
              placeholder="请选择"
            >
              <el-option label="短信" value="1"></el-option>
              <el-option label="微信" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="部门是否互看" prop="is_public">
            <el-select v-model="ruleForm.is_public" placeholder="请选择">
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="办结时限" prop="plan_time">
            <el-date-picker
              v-model="ruleForm.plan_time"
              value-format="yyyy-MM-dd"
              :picker-options="option"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="拟办意见" prop="opinion">
            <el-input
              style="width: 220px"
              type="textarea"
              :rows="3"
              placeholder="请输入内容"
              v-model="ruleForm.opinion"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </overlay-scrollbars>
      <div style="display: flex; height: 40px; line-height: 40px">
        <div style="flex: 0 0 50%">
          <el-button
            @click="doSave('ruleForm')"
            style="width: 100%; borderradius: 0"
            type="primary"
            >确定</el-button
          >
        </div>
        <div style="flex: 0 0 50%">
          <el-button @click="handleClose" style="width: 100%; border-radius: 0"
            >取消</el-button
          >
        </div>
      </div>
    </el-drawer>

    <el-dialog
      title="舆情办结"
      :visible.sync="dialogVisible"
      width="30%"
      :size="600"
      :before-close="dialogClose"
    >
      <span
        >舆情下存在未完成任务，若办结该舆情，则其下发的所有任务将自动办结，是否继续？</span
      >
      <overlay-scrollbars
        ref="userManageComponentRef"
        :style="{ height: '200px', width: '100%', paddingRight: '60px' }"
        :overflowBehavior="{ x: 'hidden', y: 'scroll' }"
      >
        <el-table :data="unfilishData" style="width: 100%">
          <el-table-column prop="department" label="部门"> </el-table-column>
          <el-table-column prop="current_status" label="状态">
          </el-table-column>
        </el-table>
      </overlay-scrollbars>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogClose">取 消</el-button>
        <el-button type="primary" @click="doTaskOver">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { wyAxiosPost } from '../utils/wyAxios'
import { host } from '../config/host.js'
import { jiema } from '../utils/jiema.js'
import AuthFilter from '../components/authFilter.vue'
import { dictTranslate } from '../utils/tool.js'
const dataModel = {
  task_level: "关注",
  execute_department: [],
  coop_department: [],
  note_method: ["1", "2"],
  is_public: "1",
  plan_time: "",
  opinion: "",
};
export default ({
  name: "Home",
  components: {
    AuthFilter
  },
  data () {
    return {
      dialogVisible: false,
      unfilishData: [],
      host: host,
      options: {},
      backTop: false,
      drawer: false,
      ruleForm: this.$lodash.cloneDeep(dataModel),
      curId: "",
      rules: {
        task_level: [
          { required: true, message: "不能为空", trigger: 'blur' }
        ],
        note_method: [
          { required: true, message: "不能为空", trigger: 'blur' }
        ],
        is_public: [
          { required: true, message: "不能为空", trigger: 'blur' }
        ],
        plan_time: [
          { required: true, message: "不能为空", trigger: 'blur' }
        ]
      },
      initSentMsg: {}, //上次的下发信息，追加下发的时候需要用到
      initDepartment: [], //除了中心办公室之外的所有部门
      initTaskList: [], //各部门处理的情况列表
      allDetail: {},  //舆情全部详情

      info: {},//舆情文章相关信息
      sentType: 'first',  //判断是下发还是追加下发
      contact: [],
      zhuijiaDepartment: [],
      com_execute_department: "",
      com_coop_department: "",
      userType: 2,
      except_department: [], //排除部门
      cur_execute_department: [],//当前可供选择的执行部门
      cur_coop_department: [], //当前可供选择的协同部门
      init_in_department: [],
      loading: false,
      option: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 8.64e7;
        }
      }
    }
  },
  mounted () {
    //需要得到用户信息控制权限，编辑和删除只能是上传的人才能做
    const token = localStorage.getItem('scxgo_token');
    const rel = jiema(token);
    const userType = rel.account_type;
    if (userType) {
      this.userType = userType;
    }
    this.ruleForm = this.$lodash.cloneDeep(dataModel);
    //获取参数query
    const { id } = this.$route.query
    this.curId = id;
    this.initScroll();
    this.getExcuteDepartment();
    this.getTaskInfo();
    this.getDetail();

    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.toBack, false);
    }
  },
  // 组件销毁阶段，解除监听返回按钮的事件
  destroyed () {
    window.removeEventListener("popstate", this.toBack, false);
  },
  methods: {
    getOpinionTypeName(){
      if(this.info.opinion_category){
        return dictTranslate(this.info.opinion_category,this.$store.state.opionCategory);
      }

      return '';
    },
    dialogClose () {
      this.dialogVisible = false;
      this.unfilishData = [];
    },
    initScroll () {
      let obj = this.$refs.test;
      let that = this;
      this.options = {
        callbacks: {
          onScroll: function () {
            let positionY = obj.osInstance().scroll().position.y;
            //let maxY = obj.obj.osInstance().scroll().max.y;
            if (positionY > 200) {
              that.backTop = true;
            } else {
              that.backTop = false;
            }
          }
        }
      }
    },
    //跳转到舆情信息页面
    toSentimentInfo () {
      this.toBack();
    },
    toBack () {
      sessionStorage.setItem("sentimentDetailBack", true);
      this.$router.history.push("/sentiment/sentimentInfo");
    },
    handleClose () {
      this.ruleForm = this.$lodash.cloneDeep(dataModel);
      this.$refs.ruleForm.resetFields();
      this.drawer = false;
    },
    showDrawer () {
      this.drawer = true;
    },
    doSent (sentType) {
      this.sentType = sentType;
      if (sentType == 'nofirst') {
        const { task_level, note_method, is_public, plan_time } = this.info;
        this.ruleForm = { task_level, note_method, is_public, plan_time };
      } else {
        this.ruleForm = this.$lodash.cloneDeep(dataModel);
      }
      // this.ruleForm.execute_department = [];
      // this.ruleForm.coop_department = [];

      this.showDrawer();


      const { except_department } = this;
      if (except_department && except_department.length > 0) {
        this.zhuijiaDepartment = this.initDepartment.filter(item => {
          if (except_department.indexOf(item.name) == -1) {
            return item;
          }
        })
      } else {
        this.zhuijiaDepartment = this.initDepartment;
      }
      this.init_in_department = this.$lodash.cloneDeep(this.zhuijiaDepartment);
      this.cur_execute_department = this.$lodash.cloneDeep(this.zhuijiaDepartment);
      this.cur_coop_department = this.$lodash.cloneDeep(this.zhuijiaDepartment);
    },
    doSave (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { execute_department, coop_department, note_method } = this.ruleForm;
          const data = this.$lodash.cloneDeep(this.ruleForm);
          if (execute_department.length == 0 && coop_department == 0) {
            this.$message.warning("执行部门和协同部门必须选择一个！");
            return;
          }
          if (this.curId) {
            data.id = this.curId;
          }
          const str_execute_department = JSON.stringify(this.$lodash.cloneDeep(execute_department));
          const str_coop_department = JSON.stringify(this.$lodash.cloneDeep(coop_department));
          const str_note_method = JSON.stringify(this.$lodash.cloneDeep(note_method));
          data.execute_department = str_execute_department;
          data.coop_department = str_coop_department;
          data.note_method = str_note_method;
          this.loading = true;
          wyAxiosPost('/detail/save', { ...data }, (res) => {
            const { code, msg } = res
            if (code === 1) {
              this.loading = false;
              this.$message({
                type: 'success',
                message: msg
              })
              this.getDetail();
              this.getTaskInfo();
              this.handleClose();
            } else {
              this.loading = false;
              this.$message({
                type: 'warning',
                message: msg
              })
            }
          })
        } else {
          return false;
        }
      });
    },
    //获取舆情信息的下发信息
    getSentMsg () {
      const id = this.curId;
      wyAxiosPost('/detail/getSentMsg', { id }, (res) => {
        const { code, data } = res
        if (code === 1) {
          this.initSentMsg = data;
        }
      })
    },
    //获取舆情信息的处理情况
    getTaskInfo () {
      const id = this.curId;
      wyAxiosPost('/detail/getTaskInfo', { id }, (res) => {
        const { code, data } = res
        if (code === 1) {
          this.initTaskList = data;
        }
      })
    },
    //获取除了中心部门之外的所有其他部门
    getExcuteDepartment () {
      wyAxiosPost('/detail/getExecuteDepartment', {}, (res) => {
        const { code, data } = res
        if (code === 1) {
          this.initDepartment = data;
        }
      })
    },
    //移除下发
    cancelSent () {
      this.$confirm('移除下发将使该条舆情处于未下发状态,移除已下发某些部门任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const id = this.curId;
        wyAxiosPost('/detail/cancelSent', { id }, (res) => {
          const { code, msg } = res
          if (code === 1) {
            this.$message.success(msg);
            this.sentType = 'first'
            this.getDetail();
          }
        })
      }).catch(() => {
        return;
      });
    },
    //发起舆情办结
    taskOver () {
      const id = this.curId;
      wyAxiosPost('/detail/checkTask', { id }, (res) => {
        const { code, msg, data } = res;
        if (code == 1) {
          if (data && data.length > 0) {
            this.unfilishData = data;
            this.dialogVisible = true;
          } else {
            this.$confirm('是否确定要办结这条舆情?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              wyAxiosPost('/detail/taskOver', { id }, (res) => {
                const { code, msg } = res
                if (code === 1) {
                  this.$message.success(msg);
                  this.getDetail();
                  this.getTaskInfo();
                }
              })
            }).catch(() => {
              return;
            });
          }
        } else {
          this.$message.warning(msg);
        }
      })

    },
    //办结舆情
    doTaskOver () {
      const id = this.curId;
      wyAxiosPost('/detail/taskOver', { id }, (res) => {
        const { code, msg } = res
        if (code === 1) {
          this.$message.success(msg);
          this.getDetail();
          this.getTaskInfo();
          this.dialogClose();
        }
      })
    },

    //获取关于该条舆情信息的所有详情信息
    getDetail () {
      const id = this.curId;
      wyAxiosPost('/detail/getDetail', { id }, (res) => {
        const { code, data } = res
        if (code === 1) {
          this.allDetail = data;
          this.info = data.info
          this.contact = data.contact
          const execute_department = data.info.execute_department;
          const coop_department = data.info.coop_department;
          //数组转字符串
          if (execute_department && execute_department.length > 0) {
            this.com_execute_department = execute_department.join(",");
          }
          if (coop_department && coop_department.length > 0) {
            this.com_coop_department = coop_department.join(",");
          }
          //合并作为排除部门
          if (coop_department && coop_department.length > 0) {
            if (execute_department && execute_department.length > 0) {
              this.except_department = coop_department.concat(execute_department);
            } else {
              this.except_department = coop_department;
            }
          } else {
            if (execute_department && execute_department.length > 0) {
              this.except_department = execute_department;
            } else {
              this.except_department = [];
            }
          }
        }
      })
    },
    goTop () {
      this.$refs.test.osInstance().update();
      this.$refs.test.osInstance().scroll({ y: "0%" }, 500);
    }
  },
  computed: {
    windowH: function () {
      return this.$store.state.windowH - 140;
    }
  },
  watch: {
    "ruleForm.execute_department" (newVal) {
      let init_in_department = this.$lodash.cloneDeep(this.init_in_department);
      const rel_coop_department = init_in_department.filter(o => {
        if (newVal.indexOf(o.id) == -1) {
          return o;
        }
      })
      this.cur_coop_department = rel_coop_department;
    },
    "ruleForm.coop_department" (newVal) {
      let init_in_department = this.$lodash.cloneDeep(this.init_in_department);
      const rel_execute_department = init_in_department.filter(o => {
        if (newVal.indexOf(o.id) == -1) {
          return o;
        }
      })
      this.cur_execute_department = rel_execute_department;
    }
  }

})
</script>

<style scoped>
.wc {
  padding: 10px 5px 5px 5px;
}
.in {
  width: 100%;
  background: #ffffff;
}
.in-t {
  border-bottom: solid #efeff4 1px;
  height: 40px;
  width: 100%;
  line-height: 40px;
  padding-left: 20px;
  font-size: 14px;
}
.subInfo {
  display: inline-block;
  margin-right: 20px;
}
.le_out {
  display: flex;
  line-height: 30px;
  font-size: 12px;
}
.le_left {
  flex: 0 0 80px;
  padding-left: 20px;
  opacity: 0.7;
}
.le_right {
  flex: 1 1 auto;
  white-space: nowrap; /*内容超宽后禁止换行显示*/
  overflow: hidden; /*超出部分隐藏*/
  text-overflow: ellipsis; /*文字超出部分以省略号显示*/
}
.upButton {
  position: fixed;
  background: #0055fe;
  bottom: 40px;
  right: 60px;
  cursor: pointer;
  width: 40px;
  padding: 6px 10px;
  border-radius: 3px;
  color: #ffffff;
  opacity: 0.8;
}
.upButton:hover {
  opacity: 0.5;
}
.upButton:active {
  opacity: 1;
}

* {
  box-sizing: border-box;
}
</style>




